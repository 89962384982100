import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import SaleSupplierModify from './pages/SaleSupplierModify';
import { saleServiceRateRoutes } from '../saleServiceRate/routes';

const SaleSupplier = lazyWithRetry(() => import('./pages/SaleSupplier'));
const SaleSupplierNew = lazyWithRetry(() => import('./pages/SaleSupplierNew'));

export const saleSupplierRoutes: { [key: number]: IRouteConfig } = {};

saleSupplierRoutes[routesIdMap.SaleSupplier] = {
	component: SaleSupplier,
	exact: true,
	id: routesIdMap.SaleSupplier,
	name: 'Sale Supplier',
	path: '/sale/supplier',
	permissions: [PermissionsType.Sale],
	icon: 'bank',
	menuText: 'Supplier',
	childrens: [routesIdMap.SaleSupplierNew, routesIdMap.SaleSupplierModify],
};

saleServiceRateRoutes[routesIdMap.SaleSupplierNew] = {
	component: SaleSupplierNew,
	exact: true,
	id: routesIdMap.SaleSupplierNew,
	name: 'Sale Supplier New',
	path: '/sale/supplier/new',
	permissions: [PermissionsType.Sale],
	icon: 'plus',
};

saleServiceRateRoutes[routesIdMap.SaleSupplierModify] = {
	component: SaleSupplierModify,
	exact: true,
	id: routesIdMap.SaleSupplierModify,
	name: 'Sale Supplier Modify',
	path: '/sale/supplier/:id',
	permissions: [PermissionsType.Sale],
	icon: 'plus',
};
