import { useLayoutEffect } from 'react';

import type { IRequestState } from '@crac/core/modules/shared/types/RequestStateType';

import { useAlertMessage } from './useAlertMessage';

export const useManageRequest = (
	request: IRequestState,
	condition: boolean,
	clearCondition: () => void,
	successMsg: string,
	successCallback?: () => void,
) => {
	const { showAlert } = useAlertMessage();

	useLayoutEffect(() => {
		if (!condition || request.inProgress) {
			return;
		}

		clearCondition();

		if (request.ok) {
			if (successCallback) {
				successCallback();
			}
			showAlert({ message: successMsg, title: 'Success', type: 'success', toast: true });
		}

		if (!request.ok && request.messages.length > 0) {
			const [{ message }] = request.messages;
			showAlert({ message });
		}
	}, [clearCondition, condition, request, showAlert, successCallback, successMsg]);
};

type ManageRequestType = {
	request: IRequestState;
	condition: boolean;
	clearCondition: () => void;
	successMsg: string;
	successCallback?: () => void;
	toast?: boolean;
};

// TODO: USAR ESTE PARA FEATURES Y CUANDO SE COMPLETE BORRAR EL DE ARRIBA
export const useManageRequestTemp = ({
	clearCondition,
	condition,
	request,
	successMsg,
	successCallback,
	toast = false,
}: ManageRequestType) => {
	const { showAlert } = useAlertMessage();

	useLayoutEffect(() => {
		if (!condition || request.inProgress) {
			return;
		}

		clearCondition();

		if (request.ok) {
			if (successCallback) {
				successCallback();
			}
			showAlert({ message: successMsg, title: 'Success', type: 'success', toast });
		}

		if (!request.ok && request.messages.length > 0) {
			const [{ message }] = request.messages;
			showAlert({ message });
		}
	}, [clearCondition, condition, request, showAlert, successCallback, successMsg, toast]);
};
