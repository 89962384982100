/* eslint-disable max-lines-per-function */
import { createSlice } from '@reduxjs/toolkit';

import {
	budgetAddFiles,
	budgetChangeWorkType,
	budgetClear,
	budgetDeleteFile,
	budgetGetByPlateNumber,
	budgetGetByVehicleAndId,
	budgetGetByVehicleLine,
	budgetGetFiles,
	budgetImport,
	budgetOpenFile,
	budgetReject,
} from '@crac/core/redux/actions/BudgetActions';
import type { IBudgetReducerState } from '@crac/core/redux-store/reducersState/budget';

const initialState: IBudgetReducerState = {
	budgetGetByPlateNumberRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetGetByVehicleAndIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetImportRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgets: [],
	budget: undefined,
	budgetGetByVehicleLine: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetChangeWorkTypeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetRejectRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetAddFilesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetDeleteFileRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetFiles: [],
	budgetGetFilesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetOpenFileRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const budgetSlice = createSlice({
	name: 'BudgetSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(budgetClear, (state) => {
			return {
				...state,
				budgets: [],
				budget: undefined,
			};
		});
		// GET BY PLATE NUMBER
		builder
			.addCase(budgetGetByPlateNumber.pending, (state) => {
				return {
					...state,
					budgetGetByPlateNumberRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetGetByPlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					budgetGetByPlateNumberRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgets: action.payload,
				};
			})
			.addCase(budgetGetByPlateNumber.rejected, (state, action) => {
				return {
					...state,
					budgetGetByPlateNumberRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY VEHICLE AND ID
		builder
			.addCase(budgetGetByVehicleAndId.pending, (state) => {
				return {
					...state,
					budgetGetByVehicleAndIdRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetGetByVehicleAndId.fulfilled, (state, action) => {
				return {
					...state,
					budgetGetByVehicleAndIdRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budget: action.payload,
				};
			})
			.addCase(budgetGetByVehicleAndId.rejected, (state, action) => {
				return {
					...state,
					budgetGetByVehicleAndIdRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// IMPORT
		builder
			.addCase(budgetImport.pending, (state) => {
				return {
					...state,
					budgetImportRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetImport.fulfilled, (state, action) => {
				return {
					...state,
					budgetImportRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgets: [...state.budgets.filter((budget) => budget.id !== action.payload.id), action.payload],
					budget: action.payload,
				};
			})
			.addCase(budgetImport.rejected, (state, action) => {
				return {
					...state,
					budgetImportRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY VEHICLE LINE
		builder
			.addCase(budgetGetByVehicleLine.pending, (state) => {
				return {
					...state,
					budgetGetByVehicleLine: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetGetByVehicleLine.fulfilled, (state, action) => {
				return {
					...state,
					budgetGetByVehicleLine: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgets: action.payload,
				};
			})
			.addCase(budgetGetByVehicleLine.rejected, (state, action) => {
				return {
					...state,
					budgetGetByVehicleLine: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		builder
			.addCase(budgetChangeWorkType.pending, (state) => {
				return {
					...state,
					budgetChangeWorkTypeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetChangeWorkType.fulfilled, (state, action) => {
				return {
					...state,
					budgetChangeWorkTypeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgets: state.budgets.filter((item) => item.id !== action.payload.id),
				};
			})
			.addCase(budgetChangeWorkType.rejected, (state, action) => {
				return {
					...state,
					budgetChangeWorkTypeRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		builder
			.addCase(budgetReject.pending, (state) => {
				return {
					...state,
					budgetRejectRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetReject.rejected, (state, action) => {
				return {
					...state,
					budgetRejectRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(budgetReject.fulfilled, (state, action) => {
				return {
					...state,
					budgetRejectRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgets: state.budgets.map((item) => (item.id === action.payload.id ? action.payload : item)),
				};
			});
		// ADD FILES
		builder
			.addCase(budgetAddFiles.pending, (state) => {
				return {
					...state,
					budgetAddFilesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetAddFiles.rejected, (state, action) => {
				return {
					...state,
					budgetAddFilesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(budgetAddFiles.fulfilled, (state, action) => {
				return {
					...state,
					budgetAddFilesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgetFiles: [...state.budgetFiles, ...action.payload],
				};
			});
		// GET FILES
		builder
			.addCase(budgetGetFiles.pending, (state) => {
				return {
					...state,
					budgetGetFilesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetGetFiles.rejected, (state, action) => {
				return {
					...state,
					budgetGetFilesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(budgetGetFiles.fulfilled, (state, action) => {
				return {
					...state,
					budgetGetFilesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgetFiles: action.payload,
				};
			});
		// OPEN FILE
		builder
			.addCase(budgetOpenFile.pending, (state) => {
				return {
					...state,
					budgetOpenFileRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetOpenFile.rejected, (state, action) => {
				return {
					...state,
					budgetOpenFileRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(budgetOpenFile.fulfilled, (state) => {
				return {
					...state,
					budgetOpenFileRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			});
		// DELETE FILE
		builder
			.addCase(budgetDeleteFile.pending, (state) => {
				return {
					...state,
					budgetDeleteFileRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetDeleteFile.rejected, (state, action) => {
				return {
					...state,
					budgetDeleteFileRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(budgetDeleteFile.fulfilled, (state, action) => {
				return {
					...state,
					budgetDeleteFileRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgetFiles: state.budgetFiles.filter(({ id }) => id !== action.payload.id),
				};
			});
	},
});

export const budgetReducer = budgetSlice.reducer;
