'use client';
import type { FC } from 'react';
import React from 'react';

import { CardHeader as RSCardHeader } from 'reactstrap';
import type { CardHeaderProps as RSCardHeaderProps } from 'reactstrap/types/lib/CardHeader';

export type CardHeaderProps = RSCardHeaderProps;

export const CardHeader: FC<CardHeaderProps> = ({ children, ...props }) => {
	return <RSCardHeader {...props}>{children}</RSCardHeader>;
};
