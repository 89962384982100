import type { FC } from 'react';
import { useMemo } from 'react';

import { formatSelect } from '@crac/core/helpers/commons';
import type { IPaymentMethod } from '@crac/core/models/entities/PaymentMethod';
import { SelectField } from '@crac/form/field';

import { usePaymentMethodSelector } from '~/features/shared/state/selectors/PaymentMethodSelector';

interface ISupplierFormSelectPaymentMethodProps {
	name: string;
	label: string;
	paymentMethods?: IPaymentMethod[];
	required?: boolean;
	isClearable?: boolean;
}

export const SupplierFormSelectPaymentMethod: FC<ISupplierFormSelectPaymentMethodProps> = ({
	paymentMethods,
	...rest
}) => {
	const { paymentMethods: defaultPaymentMethods } = usePaymentMethodSelector();

	const paymentMethodsOptions = useMemo(
		() => formatSelect('description', 'code', paymentMethods || defaultPaymentMethods),
		[paymentMethods, defaultPaymentMethods],
	);

	return <SelectField options={paymentMethodsOptions} {...rest} />;
};
