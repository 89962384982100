import { useState } from 'react';

import type { ISupplierInsertParams } from '@crac/core/modules/supplier/services/SupplierInsertService';
import { supplierInsert } from '@crac/core/modules/supplier/state/actions/SupplierActions';
import type { SupplierType } from '@crac/core/modules/supplier/types/SupplierType';

import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';
import { useManageRequestTemp } from '~/features/shared/hooks/useManageRequest';
import { useSupplierSelector } from '~/features/shared/state/selectors/SupplierSelectors';

import { useSupplierFormCancel } from './useSupplierFormCancel';

const actionsImported = { supplierInsert };

interface IUseSupplierInsertParams {
	cancelRoute?: string;
	resetAddress?: boolean;
	supplierType: SupplierType;
}

/**
 * Hook to insert a supplier
 * @param IUseSupplierInsertParams
 * @returns { handleInsert }
 */
export const useSupplierInsert = ({ supplierType, cancelRoute, resetAddress }: IUseSupplierInsertParams) => {
	const [isSubmitted, setIsSubmitted] = useState(false);

	const { handleCancel } = useSupplierFormCancel({ cancelRoute, resetAddress });

	const { showConfirm } = useAlertMessage();
	const { supplierInsert } = useBindAndMemoizeActions(actionsImported);
	const { supplierInsertRequest } = useSupplierSelector();

	const handleInsert = async (values: ISupplierInsertParams) => {
		if (!supplierType) {
			// eslint-disable-next-line no-console
			console.error(new Error('Supplier type is required'));

			return;
		}

		const confirm = await showConfirm({
			message: 'Are you sure you want to insert this supplier?',
			confirmButtonText: 'Yes, insert it',
		});

		if (confirm) {
			supplierInsert({ ...values, type: supplierType });
			setIsSubmitted(true);
		}
	};

	useManageRequestTemp({
		clearCondition: () => setIsSubmitted(false),
		condition: isSubmitted,
		request: supplierInsertRequest,
		successMsg: 'Supplier inserted successfully',
		toast: true,
		successCallback: handleCancel,
	});

	return { handleInsert };
};
