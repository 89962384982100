import { createSyncAction } from '../../../shared/state/createAction';
import { createAsyncAction } from '../../../shared/state/createAsyncAction';
import type { ISupplier } from '../../entities/Supplier';
import type { ISupplierGetAllParams } from '../../services/SupplierGetAllService';
import { supplierServiceGetAll } from '../../services/SupplierGetAllService';
import type { ISupplierInsertParams } from '../../services/SupplierInsertService';
import { supplierInsertService } from '../../services/SupplierInsertService';
import type { ISupplierModifyParams } from '../../services/SupplierModifyService';
import { supplierModifyService } from '../../services/SupplierModifyService';
import type { ISupplierSearchParams } from '../../services/SupplierSearchService';
import { supplierSearchService } from '../../services/SupplierSearchService';

/**
 * Retrieves a supplier list.
 *
 * @param {ISupplier[]} payload - The list of suppliers.
 * @param {ISupplierSearchParams} params - Parameters for searching the supplier.
 * @returns {Promise<ServiceResponse<ISupplier[]>>} - A promise that resolves to the list of suppliers.
 */
export const supplierSearch = createAsyncAction<ISupplier[], ISupplierSearchParams>(
	'supplier/search',
	supplierSearchService,
);

/**
 * Inserts a new supplier.
 *
 * @param {ISupplier} payload - The supplier to be inserted.
 * @param {ISupplierInsertParams} params - Parameters for inserting the supplier.
 * @returns {Promise<ServiceResponse<ISupplier>>}  - A promise that resolves to the inserted supplier.
 */
export const supplierInsert = createAsyncAction<ISupplier, ISupplierInsertParams>(
	'supplier/insert',
	supplierInsertService,
);

/**
 * Modifies an existing supplier.
 *
 * @param {ISupplier} payload - The supplier to be modified.
 * @param {ISupplierModifyParams} params - Parameters for modifying the supplier.
 * @returns {Promise<ServiceResponse<ISupplier>>}  - A promise that resolves to the modified supplier.
 */
export const supplierModify = createAsyncAction<ISupplier, ISupplierModifyParams>(
	'supplier/modify',
	supplierModifyService,
);

/**
 * Retrieves all suppliers.
 *
 * @param {ISupplier[]} payload - The suppliers to be retrieved.
 * @param {ISupplierGetAllParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array of all suppliers.
 */
export const supplierGetAll = createAsyncAction<ISupplier[], ISupplierGetAllParams>(
	'supplier/getAll',
	supplierServiceGetAll,
);

/**
 * Clears the supplier state.
 */
export const supplierClear = createSyncAction('supplier/clear');
