import { combineReducers } from '@reduxjs/toolkit';

import { batchReducer as Batch } from './batch/state/slice';
import { brandParkingReducer as BrandParking } from './brandParkings/state/slice';
import { connectedVehiclesReducer as ConnectedVehicles } from './connectedVehicles/state/connectedVehiclesSlice';
import { documentationReducer as Documentation } from './documentation/state/slice';
import { fineReducer as Fine } from './fine/state/slice';
import { fineReasonReducer as FineReason } from './fineReason/state/slice';
import { fineTransmitterReducer as FineTransmitter } from './fineTransmitter/state/slice';
import { insuranceRateReducer as InsuranceRate } from './insuranceRate/state/slice';
import { metaModelReducer as MetaModel } from './metaModel/state/slice';
import { movementRestrictionReducer as MovementRestriction } from './movementRestriction/state/slice';
import { previousRegistrationReducer as PreviousRegistration } from './previousRegistration/state/slice';
import { previsionReducer as Prevision } from './prevision/state/slice';
import { transferReducer as Transfer } from './transfer/state/slice';
import { vehicleBrandReducer as VehicleBrand } from './vehicleBrand/state/slice';
import { vehicleColorReducer as VehicleColor } from './vehicleColor/state/slice';
import { vehicleEngineReducer as VehicleEngine } from './vehicleEngine/state/slice';
import { vehicleGroupReducer as VehicleGroup } from './vehicleGroup/state/slice';
import { vehicleModelReducer as VehicleModel } from './vehicleModel/state/slice';
import { vehicleModifyReducer as VehicleModify } from './vehicleModify/state/slice';
import { vehicleVersionReducer as VehicleVersion } from './vehicleVersion/state/slice';

export const fleetReducer = combineReducers({
	Batch,
	BrandParking,
	ConnectedVehicles,
	Documentation,
	Fine,
	FineReason,
	FineTransmitter,
	InsuranceRate,
	MetaModel,
	MovementRestriction,
	PreviousRegistration,
	Prevision,
	Transfer,
	VehicleBrand,
	VehicleColor,
	VehicleEngine,
	VehicleGroup,
	VehicleModel,
	VehicleModify,
	VehicleVersion,
});
