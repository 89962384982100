import type { FC } from 'react';

import { Button } from '@crac/components/external/reactstrap/button';
import { Card, CardBody, CardFooter, CardHeader } from '@crac/components/external/reactstrap/card';
import { Form } from '@crac/form/form';
import { FormState } from '@crac/form/form/FormState';

import type { ISupplierFormContentProps } from '../types';

interface ISupplierFormContentWrapperProps extends ISupplierFormContentProps {
	children: React.ReactNode;
}

export const SupplierFormContentWrapper: FC<ISupplierFormContentWrapperProps> = ({
	children,
	initialValues,
	onSubmit,
	title,
	onCancel,
}) => {
	const isEdit = initialValues !== undefined;
	const icon = isEdit ? 'pencil' : 'plus';

	return (
		<Form aria-label="supplier-form" initialValues={initialValues} mode="onChange" onSubmit={onSubmit}>
			{/* Se quitado la prop isValid para que no se revaliden los campos en cada onChange */}
			{() => (
				<Card>
					<CardHeader>
						<h4>
							{' '}
							<i className={`fa fa-fw fa-${icon}`} />
							{title}
						</h4>
					</CardHeader>
					<CardBody>
						{/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
						{children}
					</CardBody>
					<CardFooter className="d-flex justify-content-between">
						<Button color="link" onClick={onCancel}>
							<i className="fa fa-fw fa-close" /> Cancel
						</Button>
						<FormState>
							{({ isValid, isDirty }) => (
								<Button color="primary" isDisabled={!isValid || !isDirty} type="submit">
									<i className="fa fa-fw fa-save" /> Save
								</Button>
							)}
						</FormState>
					</CardFooter>
				</Card>
			)}
		</Form>
	);
};
