import { VehicleEngineEndPoints } from './VehicleEngineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicleEngine } from '../entities/VehicleEngine';

export interface IVehicleEngineSearchParams extends IServiceBaseParams {
	brand?: string;
	metaModelId?: number;
	name?: string;
}

export const vehicleEngineServiceSearch = (params: IVehicleEngineSearchParams) => {
	return Api.get<IVehicleEngine[], IVehicleEngineSearchParams>(VehicleEngineEndPoints.SEARCH, params);
};
