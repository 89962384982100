import { BatchEndPoints } from './BatchEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBatch } from '../entities/Batch';

export interface IBatchDeleteParams extends IServiceBaseParams {
	code: string;
}

/**
 * Delete a batch
 * @return {Promise<BatchType>} `BatchType`
 */
export const batchDeleteService = (model: IBatchDeleteParams): Promise<ServiceResponse<IBatch>> => {
	return Api.post<IBatch, IBatchDeleteParams>(BatchEndPoints.DELETE, model);
};
