import type { ICheckTin, ICheckVat } from '../../models/entities/Validators';
import type {
	IValidatorsCheckTinParams,
	IValidatorsCheckVatParams,
	IValidatorsTokenCaptchaParams,
} from '../../models/serviceParams/ValidatorsParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ValidatorsEndPoints } from '../endPoints/ValidatorsEndPoints';

/**
 * Check a VAT number
 *  {string} countryCode Country code
 *  {string} varNumber VAT number
 * @returns {Promise} `ResponsePropType`
 */
export const validatorsServiceCheckVAT = (model: IValidatorsCheckVatParams): Promise<ServiceResponse<ICheckVat>> => {
	return Api.get<ICheckVat, IValidatorsCheckVatParams>(ValidatorsEndPoints.CHECK_VAT, model);
};

/**
 * Check a TIN number
 *  {string} countryCode Country code
 *  {string} tinNumber TIN number
 * @returns {Promise} `ResponsePropType`
 */
export const validatorsServiceCheckTIN = (model: IValidatorsCheckTinParams): Promise<ServiceResponse<ICheckTin>> => {
	return Api.get<ICheckTin, IValidatorsCheckTinParams>(ValidatorsEndPoints.CHECK_TIN, model);
};

/**
 * Validate re captcha token
 */
export const validatorsServiceTokenCaptcha = (
	model: IValidatorsTokenCaptchaParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, IValidatorsTokenCaptchaParams>(ValidatorsEndPoints.VALIDATE_CAPTCHA, model);
};
