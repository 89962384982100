import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import type { IFranchiseLine } from '../../../../models/entities/FranchiseLine';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderStartReconditioningParams extends IServiceBaseParams {
	id: number;
	franchiseLines: IFranchiseLine[];
	workshopCode: string;
}

/**
 * Starts reconditioning
 * @param params - Parameters to start reconditioning.
 * @returns A promise that resolves a sale order.
 */
export const saleOrderStartReconditioningService = (params: ISaleOrderStartReconditioningParams) => {
	return Api.post<ISaleOrder, ISaleOrderStartReconditioningParams>(SaleOrderEndPoints.START_RECONDITIONING, params);
};
