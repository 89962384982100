import type { FC } from 'react';
import { useMemo } from 'react';

import { formatSelect } from '@crac/core/helpers/commons';
import type { IProvider } from '@crac/core/models/entities/Provider';
import { SelectField } from '@crac/form/field';

import { useProviderSelector } from '~/features/shared/state/selectors/ProviderSelectors';

interface ISelectProvider {
	providers?: IProvider[];
	label?: string;
	name: string;
	id?: string;
	required?: boolean;
	validateOnLoad?: boolean;
	isClearable?: boolean;
	disabled?: any;
	onChange?: (value: string | number | boolean | undefined) => void;
	noValidate?: boolean;
}

export const SelectProvider: FC<ISelectProvider> = ({
	providers,
	required,
	noValidate,
	label = 'Company',
	...props
}): JSX.Element => {
	const { providers: defaultProviders } = useProviderSelector();
	const providerOptions = useMemo(
		() => formatSelect('name', 'name', providers || defaultProviders),
		[providers, defaultProviders],
	);

	const isFieldRequired = useMemo(() => required, [required]);

	return (
		<SelectField
			{...props}
			label={label}
			options={providerOptions}
			required={isFieldRequired}
			validation={{
				required: isFieldRequired && !noValidate,
			}}
		/>
	);
};
