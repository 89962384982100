/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { createSlice } from '@reduxjs/toolkit';

import type { ISaleOrder } from '@crac/core/modules/sale/saleOrder/entities/SaleOrder';
import {
	saleOrderAcceptReconditioning,
	saleOrderCancel,
	saleOrderChangeAmounts,
	saleOrderClear,
	saleOrderConfirmChangeOfOwnership,
	saleOrderConfirmDelivery,
	saleOrderConfirmDepositReceived,
	saleOrderConfirmPaymentReceived,
	saleOrderConfirmPrice,
	saleOrderConfirmReconditioning,
	saleOrderGetById,
	saleOrderInsert,
	saleOrderLineGetByOrderId,
	saleOrderRemoveUnsold,
	saleOrderSearch,
	saleOrderSetBlockToSale,
	saleOrderStartReconditioning,
} from '@crac/core/modules/sale/saleOrder/state/SaleOrderActions';
import type { ISaleOrderReducerState } from '@crac/core/modules/sale/saleOrder/state/SaleOrderReducerState';
import {
	saleTicketGetByPlateNumber,
	saleTicketsClear,
} from '@crac/core/modules/sale/saleTicket/state/SaleTicketActions';
import { companyGetByCode, companyInsert, companyModify } from '@crac/core/redux/actions/CompanyActions';
import {
	customerClear,
	customerGetByCode,
	customerGetDetailData,
	customerModify,
	customerSave,
	customerSearch,
} from '@crac/core/redux/actions/CustomerActions';

const initialState: ISaleOrderReducerState = {
	saleOrderInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
		data: null,
	},
	saleOrders: [],
	saleOrderSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrder: null,
	saleOrderGetByIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderSetBlockToSaleRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderRemoveUnsoldRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderChangeAmountsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderCancelRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderConfirmDepositReceivedRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderStartReconditioningRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderConfirmReconditioningRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderConfirmPriceRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderLines: [],
	saleOrderLineGetByOrderIdRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customer: null,
	customerSaveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	customerGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	company: null,
	companySaveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	companyGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleTicketGetByPlateNumberRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleTickets: [],
	saleOrderAcceptReconditioningRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderConfirmPaymentReceivedRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderConfirmDeliveryRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleOrderConfirmChangeOfOwnershipRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const saleOrderSlice = createSlice({
	name: 'SaleOrderSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY ID
		builder
			.addCase(saleOrderGetById.pending, (state) => {
				return {
					...state,
					saleOrderGetByIdRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderGetById.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrderGetByIdRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderGetById.rejected, (state, action) => {
				return {
					...state,
					saleOrderGetByIdRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(saleOrderSearch.pending, (state) => {
				return {
					...state,
					saleOrderSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderSearch.fulfilled, (state, action) => {
				return {
					...state,
					saleOrders: action.payload,
					saleOrderSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderSearch.rejected, (state, action) => {
				return {
					...state,
					saleOrderSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(saleOrderInsert.pending, (state) => {
				return {
					...state,
					saleOrderInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
						data: null,
					},
				};
			})
			.addCase(saleOrderInsert.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
						data: action.payload as ISaleOrder,
					},
				};
			})
			.addCase(saleOrderInsert.rejected, (state, action) => {
				return {
					...state,
					saleOrderInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
						data: null,
					},
				};
			});

		// CLEAR
		builder.addCase(saleOrderClear, () => {
			return initialState;
		});
		// ORDER LINES GET BY ORDER ID
		builder
			.addCase(saleOrderLineGetByOrderId.pending, (state) => {
				return {
					...state,
					saleOrderLineGetByOrderIdRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderLineGetByOrderId.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderLines: action.payload,
					saleOrderLineGetByOrderIdRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderLineGetByOrderId.rejected, (state, action) => {
				return {
					...state,
					saleOrderLineGetByOrderIdRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET TICKET BY PLATE NUMBER
		builder
			.addCase(saleTicketGetByPlateNumber.pending, (state) => {
				return {
					...state,
					saleTickets: [],
					saleTicketGetByPlateNumberRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleTicketGetByPlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					saleTickets: action.payload,
					saleTicketGetByPlateNumberRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleTicketGetByPlateNumber.rejected, (state, action) => {
				return {
					...state,
					saleTicketGetByPlateNumberRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR SALE TICKETS
		builder.addCase(saleTicketsClear, (state) => {
			return {
				...state,
				saleTickets: [],
			};
		});
		// SEARCH CUSTOMER
		builder
			.addCase(customerSearch.pending, (state) => {
				return {
					...state,
					customer: null,
					customerSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSearch.fulfilled, (state, action) => {
				return {
					...state,
					customer: action.payload.length ? action.payload[0] : null,
					customerSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSearch.rejected, (state, action) => {
				return {
					...state,
					customerSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CUSTOMER GET BY CODE
		builder
			.addCase(customerGetByCode.pending, (state) => {
				return {
					...state,
					customer: null,
					customerGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					customer: action.payload,
					customerGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetByCode.rejected, (state, action) => {
				return {
					...state,
					customerGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CUSTOMER GET BY CODE AND LOAD DETAIL DATA
		builder
			.addCase(customerGetDetailData.pending, (state) => {
				return {
					...state,
					customer: null,
					customerSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetDetailData.fulfilled, (state, action) => {
				return {
					...state,
					customer: action.payload?.customer ?? null,
					customerSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetDetailData.rejected, (state, action) => {
				return {
					...state,
					customerSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT CUSTOMER
		builder
			.addCase(customerSave.pending, (state) => {
				return {
					...state,
					customer: null,
					customerSaveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSave.fulfilled, (state, action) => {
				return {
					...state,
					customer: action.payload,
					customerSaveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSave.rejected, (state, action) => {
				return {
					...state,
					customerSaveRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY CUSTOMER
		builder
			.addCase(customerModify.pending, (state) => {
				return {
					...state,
					customer: null,
					customerSaveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerModify.fulfilled, (state, action) => {
				return {
					...state,
					customer: action.payload,
					customerSaveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerModify.rejected, (state, action) => {
				return {
					...state,
					customerSaveRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR CUSTOMER
		builder.addCase(customerClear, (state) => {
			return {
				...state,
				customer: null,
			};
		});
		// COMPANY GET BY CODE
		builder
			.addCase(companyGetByCode.pending, (state) => {
				return {
					...state,
					company: null,
					companyGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					company: action.payload,
					companyGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyGetByCode.rejected, (state, action) => {
				return {
					...state,
					companyGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// COMPANY INSERT
		builder
			.addCase(companyInsert.pending, (state) => {
				return {
					...state,
					company: null,
					companySaveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyInsert.fulfilled, (state, action) => {
				return {
					...state,
					company: action.payload,
					companySaveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyInsert.rejected, (state, action) => {
				return {
					...state,
					companySaveRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// COMPANY MODIFY
		builder
			.addCase(companyModify.pending, (state) => {
				return {
					...state,
					company: null,
					companySaveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyModify.fulfilled, (state, action) => {
				return {
					...state,
					company: action.payload,
					companySaveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyModify.rejected, (state, action) => {
				return {
					...state,
					companySaveRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SET BLOCK TO SALE AT
		builder
			.addCase(saleOrderSetBlockToSale.pending, (state) => {
				return {
					...state,
					saleOrderSetBlockToSaleRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderSetBlockToSale.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrderSetBlockToSaleRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderSetBlockToSale.rejected, (state, action) => {
				return {
					...state,
					saleOrderSetBlockToSaleRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REMOVE UNSOLD
		builder
			.addCase(saleOrderRemoveUnsold.pending, (state) => {
				return {
					...state,
					saleOrderRemoveUnsoldRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderRemoveUnsold.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrderRemoveUnsoldRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderRemoveUnsold.rejected, (state, action) => {
				return {
					...state,
					saleOrderRemoveUnsoldRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE AMOUNTS
		builder
			.addCase(saleOrderChangeAmounts.pending, (state) => {
				return {
					...state,
					saleOrderChangeAmountsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderChangeAmounts.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrderChangeAmountsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderChangeAmounts.rejected, (state, action) => {
				return {
					...state,
					saleOrderChangeAmountsRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CANCEL
		builder
			.addCase(saleOrderCancel.pending, (state) => {
				return {
					...state,
					saleOrderCancelRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderCancel.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
					saleOrderCancelRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderCancel.rejected, (state, action) => {
				return {
					...state,
					saleOrderCancelRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CONFIRM DEPOSIT RECEIVED
		builder
			.addCase(saleOrderConfirmDepositReceived.pending, (state) => {
				return {
					...state,
					saleOrderConfirmDepositReceivedRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderConfirmDepositReceived.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
					saleOrderConfirmDepositReceivedRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderConfirmDepositReceived.rejected, (state, action) => {
				return {
					...state,
					saleOrderConfirmDepositReceivedRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// START RECONDITIONING
		builder
			.addCase(saleOrderStartReconditioning.pending, (state) => {
				return {
					...state,
					saleOrderStartReconditioningRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderStartReconditioning.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
					saleOrderStartReconditioningRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderStartReconditioning.rejected, (state, action) => {
				return {
					...state,
					saleOrderStartReconditioningRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// ACCEPT RECONDITIONING
		builder
			.addCase(saleOrderAcceptReconditioning.pending, (state) => {
				return {
					...state,
					saleOrderAcceptReconditioningRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderAcceptReconditioning.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
					saleOrderAcceptReconditioningRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderAcceptReconditioning.rejected, (state, action) => {
				return {
					...state,
					saleOrderAcceptReconditioningRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CONFIRM RECONDITIONING
		builder
			.addCase(saleOrderConfirmReconditioning.pending, (state) => {
				return {
					...state,
					saleOrderConfirmReconditioningRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderConfirmReconditioning.fulfilled, (state, action) => {
				return {
					...state,
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
					saleOrderConfirmReconditioningRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderConfirmReconditioning.rejected, (state, action) => {
				return {
					...state,
					saleOrderConfirmReconditioningRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CONFIRM PAYMENT RECEIVED
		builder
			.addCase(saleOrderConfirmPaymentReceived.pending, (state) => {
				return {
					...state,
					saleOrderConfirmPaymentReceivedRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderConfirmPaymentReceived.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderConfirmPaymentReceivedRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
				};
			})
			.addCase(saleOrderConfirmPaymentReceived.rejected, (state, action) => {
				return {
					...state,
					saleOrderConfirmPaymentReceivedRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CONFIRM DELIVERY
		builder
			.addCase(saleOrderConfirmDelivery.pending, (state) => {
				return {
					...state,
					saleOrderConfirmDeliveryRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderConfirmDelivery.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderConfirmDeliveryRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
				};
			})
			.addCase(saleOrderConfirmDelivery.rejected, (state, action) => {
				return {
					...state,
					saleOrderConfirmDeliveryRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CONFIRM CHANGE OF OWNERSHIP
		builder
			.addCase(saleOrderConfirmChangeOfOwnership.pending, (state) => {
				return {
					...state,
					saleOrderConfirmChangeOfOwnershipRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderConfirmChangeOfOwnership.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderConfirmChangeOfOwnershipRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
				};
			})
			.addCase(saleOrderConfirmChangeOfOwnership.rejected, (state, action) => {
				return {
					...state,
					saleOrderConfirmChangeOfOwnershipRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CONFIRM PRICE
		builder
			.addCase(saleOrderConfirmPrice.pending, (state) => {
				return {
					...state,
					saleOrderConfirmPriceRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(saleOrderConfirmPrice.fulfilled, (state, action) => {
				return {
					...state,
					saleOrderConfirmPriceRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					saleOrder: action.payload,
					saleOrders: state.saleOrders.map((saleOrder) =>
						saleOrder.id === action.payload.id ? action.payload : saleOrder,
					),
				};
			})
			.addCase(saleOrderConfirmPrice.rejected, (state, action) => {
				return {
					...state,
					saleOrderConfirmPriceRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const saleOrderReducer = saleOrderSlice.reducer;
