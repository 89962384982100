/* eslint-disable no-constant-binary-expression */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { LinkEndPoints } from '../data/endPoints/LinkEndPoints';
/* eslint-disable max-lines */
import type { IBranch } from '../models/entities/Branch';
import type { ICountry } from '../models/entities/Country';
import type { IEmployee } from '../models/entities/Employee';
import type { IProvider } from '../models/entities/Provider';
import { FuelType } from '../models/types/FuelType';
import type { ISelectOptionType } from '../models/types/SelectOptionType';
import { API_ENDPOINT } from '../modules/shared/api/ApiEndPoint';

interface StringMap {
	[s: string]: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const getObjectKeys = <O extends object>(obj: O): Array<keyof O> => {
	return Object.keys(obj) as Array<keyof O>;
};

export interface IFormatNumberOptions {
	type?: 'percent' | 'currency' | 'decimal';
	locale?: string;
	currencyType?: string;
	value?: string | number;
}

export const getFormatNumber = (
	value: IFormatNumberOptions['value'],
	type?: IFormatNumberOptions['type'],
	locale = 'es-ES',
	currencyType = 'EUR',
	showDecimals = true,
): string => {
	let finalvalue = Number(value);
	let config: Intl.NumberFormatOptions = {};

	switch (type) {
		case 'percent':
			finalvalue = Number(Number(finalvalue) / 100);

			config = {
				style: 'percent',
			};
			break;
		case 'currency':
			finalvalue = Number(finalvalue.toString());
			config = {
				currency: currencyType,
				style: 'currency',
				maximumFractionDigits: showDecimals ? 2 : 0,
			};
			break;

		default:
			config = {
				style: 'decimal',
			};
			break;
	}

	const formattedValue = new Intl.NumberFormat(locale, config).format(Number(finalvalue));

	return ['es-ES', 'es'].includes(locale)
		? formattedValue.replace(/\B(?:(?=(?:\d{3})+(?!\d)))/gu, '.')
		: formattedValue;
};

/**
 * Change first latter to uppercase
 * @param {string} string value to capitalize
 * @returns {string} `string` string capitalize
 */
export const capitalize = (string: string): string => {
	if (string) {
		return `${string.charAt(0).toUpperCase()}${string.slice(1).toLocaleLowerCase()}`;
	}

	return string;
};

/**
 * Remplace string by newString
 * @param {string} string string remplace
 * @param {string} newstringValue new string remplace
 */
export const replaceUnderScore = (stringValue = '', newstringValue = ' '): string => {
	if (stringValue && stringValue.includes('_')) {
		return stringValue.replace(/_/gu, newstringValue);
	}

	return stringValue;
};

/**
 * Find key by object value
 * @param {Object} object Object type
 * @param {any} value value in object
 */
export const getKeyByValue = (object: any, value: string | number): string => {
	const response = Object.keys(object)
		// eslint-disable-next-line radix
		.filter((key) => !(parseInt(key) >= 0))
		.find((key) => {
			if (typeof object[key] === 'string') {
				return object[key] === value;
			}

			if (typeof object[key] === 'number') {
				return Number(object[key]) === Number(value);
			}

			return object[key] === value;
		});

	if (!response) {
		return '';
	}

	return response;
};

export const getValueFromEnumKey = (object: any, enumkey: string): number => {
	const objectKey = Object.keys(object)
		// eslint-disable-next-line radix
		.filter((key) => !(parseInt(key) >= 0))
		.find((key) => key === enumkey);

	if (!objectKey) {
		return 0;
	}

	return object[objectKey];
};

/**
 * Separate word from camelcase
 * @param text Text to separate
 */
// eslint-disable-next-line prefer-named-capture-group
export const splitCamelCase = (text: string): string => capitalize(text.replace(/([a-z])([A-Z])/gu, '$1 $2'));

/**
 * Normalize string
 * Captalize text, ReplaceUnderScore and separe camelcase
 *
 * @param {Object} object Object type
 * @param {any} value Object value
 */
export const normalizeStringCode = (value: string | number, object?: any): string => {
	if (object) {
		return capitalize(replaceUnderScore(splitCamelCase(getKeyByValue(object, value))));
	}

	return capitalize(splitCamelCase(replaceUnderScore(value as string)));
};

/**
 * Remove item from list
 *  @param {array} list List comments
 *  @param {object} object Object comment
 *  @param {string} key Name field for search in list
 */
export const removeItemFromList = (key: string, list: any[] = [], object: StringMap = {}): any[] => {
	const isEmptyObject = Object.keys(object).length === 0;

	if ((list && list.length === 0) || isEmptyObject) {
		return list;
	}

	if (object) {
		const index = list.findIndex((item) => item[key] === object[key]);
		if (index > -1) {
			list.splice(index, 1);
		}
	}

	return list;
};

export const renderFuelType = (value: string | number): string =>
	splitCamelCase(normalizeStringCode(Number(value), FuelType));

/**
 * Copy object without reference
 * @param obj Object
 */
export const deepCopy = <T>(obj: T): T => {
	if (typeof JSON === 'undefined') {
		return obj;
	}

	return JSON.parse(JSON.stringify(obj)) as T;
};

/**
 * Truncate text to specific number of characters
 * @param text Text to truncate
 * @param maxLength Max num characters
 */
export const truncateText = (text: string, maxLength: number): string => {
	if (text && maxLength && text.length > maxLength) {
		return `${text.substring(0, maxLength)}...`;
	}

	return text;
};

const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg', 'webp'];
export const isImageExtension = (extension: string): boolean => imageExtensions.includes(extension.replace('.', ''));

/**
 * Group array by one property
 * @param {Array} items
 * @param {Function} keyGetter Function Callback return property for grouping
 * @example
 * const vehiclesByPlateNumber = GroupBy(vehicles, item => item.plateNumber);
 */
export const groupBy = <V>(items: V[], keyGetter: (item: V) => string): Map<string, V[]> => {
	const map = new Map<string, V[]>();
	items.forEach((item: V) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (collection) {
			collection.push(item);
		} else {
			map.set(key, [item]);
		}
	});
	return map;
};

/**
 * Retrn list GC agencies
 * @param provider Provider
 */
export const getGcAgenciesByProvider = (provider: any) =>
	Object.keys(provider)
		.filter((providerProp) => providerProp.toLowerCase().includes('agencycode'))
		.map((prop) => provider[prop]);

/**
 * Return branch from localstorage
 */
export const getBranches = (): IBranch[] => {
	if (typeof localStorage === 'undefined') {
		return [];
	}

	if (localStorage.getItem('branches')) {
		return JSON.parse(localStorage.getItem('branches') as any);
	}

	return [];
};

/**
 * Return time zone by branch
 * @param branchCode branch code
 */
export const getTimeZoneByBranch = (branchCode: string): string => {
	if (branchCode) {
		const branches = getBranches();
		const branchTimeZone = branches.find((branch: IBranch) => branch.code === branchCode);

		if (branchTimeZone && branchTimeZone.timeZone) {
			return branchTimeZone.timeZone;
		}
	}

	return 'UTC';
};

export const getProviderByBranch = (branchCode: string): string | null => {
	const branches = getBranches();
	const result = branches.find((branch: IBranch) => branch.code === branchCode);

	if (!result) {
		return null;
	}

	return result.provider;
};

/**
 * Get list providers
 */
export const getProviders = (): IProvider[] => {
	if (typeof localStorage === 'undefined') {
		return [];
	}

	if (localStorage.getItem('providers')) {
		return JSON.parse(localStorage.getItem('providers') as any);
	}

	return [];
};

/**
 * Return provider by pased branch
 * @param branchCode Branch code
 */
export const getProviderObjectByBranch = (branchCode: string): IProvider | null => {
	const branches: IBranch[] = getBranches();
	const providers: IProvider[] = getProviders();

	const providerBranch = branches.find((branch) => branch.code === branchCode);
	let providerName: string | null = null;

	if (providerBranch) {
		providerName = providerBranch.provider;
	}

	if (providerName) {
		return providers.find((provider) => provider.name === providerName) || null;
	}

	return null;
};

/**
 * Return provider Object
 * @param branchCode branchCode
 * @param allowedBranches Branch list allowed
 */
export const getProviderObjectByBranchAllowed = (branchCode: string, allowedBranches: IBranch[]): IProvider | null => {
	if (allowedBranches) {
		const providers = getProviders();

		const branch = allowedBranches.find((bran) => bran.code === branchCode);
		if (branch) {
			return providers.find((provider) => provider.name === branch.provider) || null;
		}
	}

	return null;
};

export const isDropOff = (): boolean => {
	if (typeof window === 'undefined') {
		return false;
	}

	return document.location.pathname.includes('bookings/drop-off');
};

export const isPickUp = (): boolean => {
	if (typeof window === 'undefined') {
		return false;
	}

	return document.location.pathname.includes('bookings/pick-up');
};

/**
 * Filter duplicate values
 * @param values list values
 * @param property property name define unique value
 */
export const getUniqueValues = (values: any[], property: string): any[] => {
	if (typeof values === 'object' && Boolean(property)) {
		const result = [];
		const map = new Map();

		for (const item of values) {
			if (!map.has(item[property])) {
				map.set(item[property], true);
				result.push(item);
			}
		}

		return result;
	}

	return values;
};

export const getObjectValueByProperty = <Type, Key extends keyof Type>(obj: Type, key: Key): any => {
	return obj[key];
};

export const compareObjects = (aParam: any, bParam: any): boolean => JSON.stringify(aParam) === JSON.stringify(bParam);

export const getKeysFromEnum = (object: any): string[] =>
	Object.keys(object).filter((key) => !(parseInt(key, 10) >= 0));

// TODO: Pasar los booleanos a un objecto de configuración
export const getOptionsFromEnum = (
	object: any,
	concatValue = false,
	splitCalmelcase = true,
	upperCase = false,
): ISelectOptionType<string | number>[] => {
	const objectKeys = getKeysFromEnum(object);

	return objectKeys.map((key: any) => {
		let label = capitalize(key);

		if (splitCalmelcase) {
			label = splitCamelCase(key);
		}

		if (concatValue) {
			label = `${object[key]} - ${capitalize(splitCamelCase(key))}`;
		}

		if (upperCase) {
			label = label.toUpperCase();
		}

		return {
			label,
			value: object[key],
		};
	});
};

export const getValuesFromEnum = (object: any): number[] => {
	const keys = Object.keys(object).filter((key) => typeof object[key as any] === 'number');
	const values = keys.map((key) => object[key as any]);

	return values;
};

export const formatSelect = <T>(label: keyof T, value: keyof T, collection: T[]): ISelectOptionType<T[keyof T]>[] =>
	collection.map((item: T) => ({
		label: item[value] === item[label] ? String(item[label]) : `${String(item[value])} - ${String(item[label])}`,
		value: item[value],
	}));

export const sortCollection = <T>(sortBy: keyof T, collection: T[]): T[] =>
	collection.sort((accum, current) => String(accum[sortBy]).localeCompare(String(current[sortBy])));

export const getBranchSelectOptions = (branches: IBranch[]) => {
	return formatSelect(
		'name',
		'code',
		branches.sort((accomulate, current) => Number(accomulate.code) - Number(current.code)),
	) as ISelectOptionType<string>[];
};

/**
 * Remove all property with undefined value
 * @param object object to remove properties undefined
 */
export const removeKeyWithUndefinedValue = (object: any): any => {
	Object.keys(object).forEach((key) => {
		if (typeof object[key] === 'undefined') {
			delete object[key];
		}
	});
};

/**
 * Remove all properties with null value
 * @param object objet to remove properties with null value
 */
export const removeKeyWithNullValue = (object: any): any => {
	Object.keys(object).forEach((key) => {
		if (object[key] === null) {
			delete object[key];
		}
	});
};

/**
 * Remove every empty string property
 * @param object object to remove empty string properties
 */
export const removeKeyWithEmptyString = (object: any): any => {
	Object.keys(object).forEach((key) => {
		if (object[key] === '') {
			delete object[key];
		}
	});
};

export const convertNullValuesToUndefined = (object: any): any => {
	const copyObject = { ...object };

	Object.keys(object).forEach((key) => {
		if (object[key] === null) {
			copyObject[key] = undefined;
		}
	});

	return copyObject;
};

/**
 * Opens a new popup window in the browser with a specific URL.
 * The window is centered on the screen and adjusted to the specified
 * dimensions.
 * @param {string} path - The specific endpoint or resource location.
 * @param {Object} [params] - Optional parameters to customize the popup window.
 * @param {string} [params.basePath=API_ENDPOINT] - The base part of the URL to which the path will be appended.
 * @param {string} [params.title='PRINT DOCUMENT'] - Optional title for the popup window.
 * @param {number} [params.width=window.screen.availWidth * 0.75] - Optional width of the window.
 * @param {number} [params.height=window.screen.availHeight * 0.8] - Optional height of the window.
 *
 * @returns {void}
 *
 * @example
 * openWindowPopUp({
 *   path: '/path/to/resource',
 *   params: {
 *     basePath: 'https://api.centauro.net',
 *     title: 'My PopUp',
 *     width: 600,
 *     height: 400,
 *   }
 * });
 */
export const openWindowPopUp = (
	path: string,
	{
		basePath = API_ENDPOINT,
		title = 'PRINT DOCUMENT',
		width = window.screen.availWidth * 0.75,
		height = window.screen.availHeight * 0.8,
		target = '',
	}: {
		basePath?: string;
		title?: string;
		width?: number;
		height?: number;
		target?: '_blank' | '_parent' | '_self' | '_top' | '';
	} = {},
): void => {
	if (typeof window !== 'undefined') {
		const isAbsoluteURL = /^https?:\/\//u.test(path);
		const url = isAbsoluteURL ? path : `${basePath}${path}`;

		const WINDOW_WIDTH = window.screen.width;
		const WINDOW_HEIGHT = window.screen.height;

		const left = WINDOW_WIDTH / 2 - width / 2;
		const top = WINDOW_HEIGHT / 2 - height / 2;

		window.open(
			url,
			target,
			`title=${title},
			toolbar=no,
			location=no,
			directories=no,
			status=no,
			menubar=no,
			scrollbars=no,
			resizable=no,
			copyhistory=no,
			width=${width},
			height=${height},
			top=${top},
			left=${left}`,
		);
	}
};

export const getPhonePrefixOptions = (countries: ICountry[]): ISelectOptionType<string>[] => {
	return countries
		.map((country) => ({ label: `${country.name} (+${country.telephonePrefix})`, value: country.code }))
		.sort((accom, current) => accom.label.localeCompare(current.label));
};

export const tokenIsExpired = (expiredDateTime?: number): boolean => {
	if (!expiredDateTime) {
		return true;
	}

	if (expiredDateTime && expiredDateTime < Date.now()) {
		return true;
	}

	return false;
};

export const getRouteLink = (linkId: number): string | undefined => {
	if (typeof window === 'undefined') {
		return undefined;
	}

	const employee = localStorage.getItem('employee');
	if (employee) {
		const { token } = JSON.parse(employee) as IEmployee;

		return `${API_ENDPOINT}${LinkEndPoints.GET_BY_ID}?id=${linkId}&Authorization=Bearer ${token}`;
	}
	return undefined;
};

const removeDiacritics = (text: string): string => {
	let textEscaped = text;
	if (text && Boolean(text.normalize)) {
		textEscaped = textEscaped.normalize('NFD').replace(/[\u0300-\u036f]/gu, '');
	}
	return textEscaped;
};

const specialCharsRegex = /[.*+?^${}()|[\]\\]/gu;

// http://www.ecma-international.org/ecma-262/5.1/#sec-15.10.2.6
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const wordCharacterRegex = /[a-z0-9_]/iu;

const whitespacesRegex = /\s+/u;

const escapeRegexCharacters = (str: string): string => str.replace(specialCharsRegex, '\\$&');

export const matchText = (text: string, query: string, anyMatch: boolean): Array<[number, number]> => {
	let textEscaped = removeDiacritics(text);
	const queryEscaped = removeDiacritics(query);
	return queryEscaped
		.trim()
		.split(whitespacesRegex)
		.filter((word) => word.length > 0)
		.reduce((result: Array<[number, number]>, word) => {
			const wordLen = word.length;
			const regex = new RegExp(escapeRegexCharacters(word), 'iu');
			let index = anyMatch ? text.toLowerCase().search(escapeRegexCharacters(word)) : text.search(regex);
			if (text && Boolean(text.normalize)) {
				index = anyMatch
					? text
							.toLowerCase()
							.normalize('NFD')
							.replace(/[\u0300-\u036f]/gu, '')
							.search(
								escapeRegexCharacters(word)
									.normalize('NFD')
									.replace(/[\u0300-\u036f]/gu, ''),
							)
					: text.search(regex);
			}
			if (index > -1) {
				result.push([index, index + wordLen]);
				textEscaped =
					textEscaped.slice(0, index) + new Array(wordLen + 1).join(' ') + textEscaped.slice(index + wordLen);
			}
			return result;
		}, [])
		.sort((match1, match2) => match1[0] - match2[0]);
};

export const parseText = (text: string, matches: Array<[number, number]>): { highlight: boolean; text: string }[] => {
	const result = [];

	if (matches.length === 0) {
		result.push({
			highlight: false,
			text,
		});
	} else if (matches[0][0] > 0) {
		result.push({
			highlight: false,
			text: text.slice(0, matches[0][0]),
		});
	}

	matches.forEach((match, index) => {
		const [startIndex, endIndex] = match;

		result.push({
			highlight: true,
			text: text.slice(startIndex, endIndex),
		});

		if (index === matches.length - 1) {
			if (endIndex < text.length) {
				result.push({
					highlight: false,
					text: text.slice(endIndex, text.length),
				});
			}
		} else if (endIndex < matches[index + 1][0]) {
			result.push({
				highlight: false,
				text: text.slice(endIndex, matches[index + 1][0]),
			});
		}
	});

	return result;
};
