import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const InsuranceSupplier = lazyWithRetry(() => import('./pages/InsuranceSupplier'));
const InsuranceSupplierNew = lazyWithRetry(() => import('./pages/InsuranceSupplierNew'));
const InsuranceSupplierModify = lazyWithRetry(() => import('./pages/InsuranceSupplierModify'));

export const insuranceSupplierRoutes: { [key: number]: IRouteConfig } = {};

insuranceSupplierRoutes[routesIdMap.FleetInsuranceSupplier] = {
	component: InsuranceSupplier,
	exact: true,
	id: routesIdMap.FleetInsuranceSupplier,
	name: 'Insurance supplier',
	path: '/fleet/insurance-supplier',
	icon: 'shield',
	menuText: 'Supplier',
	permissions: [PermissionsType.Fleet],
	childrens: [routesIdMap.FleetInsuranceSupplierNew, routesIdMap.FleetInsuranceSupplierModify],
};

insuranceSupplierRoutes[routesIdMap.FleetInsuranceSupplierNew] = {
	component: InsuranceSupplierNew,
	exact: true,
	id: routesIdMap.FleetInsuranceSupplierNew,
	name: 'New insurance supplier',
	path: '/fleet/insurance-supplier/new',
	permissions: [PermissionsType.Fleet],
	icon: 'plus',
	menuText: 'New insurance supplier',
};

insuranceSupplierRoutes[routesIdMap.FleetInsuranceSupplierModify] = {
	component: InsuranceSupplierModify,
	exact: true,
	id: routesIdMap.FleetInsuranceSupplierModify,
	name: 'Modify insurance supplier',
	path: '/fleet/insurance-supplier/:id',
	permissions: [PermissionsType.Fleet],
};
