import type { ReactNode } from 'react';
import React from 'react';

import type { ControllerRenderProps, FieldValues } from 'react-hook-form';

import { SelectAllLink } from './styles';
import type { ISelectOption } from './types';

interface ISelectAllController<T> {
	children: ReactNode;
	field: ControllerRenderProps<FieldValues, string>;
	disabled?: boolean;
	canSelectAll?: boolean;
	options?: ISelectOption<T>[];
}

export const SelectAllController = <T,>({
	children,
	field,
	canSelectAll,
	disabled,
	options,
}: ISelectAllController<T>) => {
	if (!canSelectAll) {
		return children;
	}

	const allSelected = options && field.value && field.value.length === options.length;

	const handleOnSelectAll = () => {
		if (options && !disabled) {
			const value = allSelected ? null : options;
			field.onChange(value);
		}
	};

	return (
		<div className="position-relative">
			<SelectAllLink
				className="position-absolute"
				onClick={handleOnSelectAll}
				style={{ right: '5px', top: '-23px', userSelect: 'none' }}
			>
				<small>{allSelected ? 'Unselect all' : 'Select all'}</small>
			</SelectAllLink>
			{children}
		</div>
	);
};
