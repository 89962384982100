import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderConfirmChangeOfOwnershipParams extends IServiceBaseParams {
	id: number;
}

export const saleOrderServiceConfirmChangeOfOwnership = (params: ISaleOrderConfirmChangeOfOwnershipParams) => {
	return Api.post<ISaleOrder, ISaleOrderConfirmChangeOfOwnershipParams>(
		SaleOrderEndPoints.CONFIRM_CHANGE_OF_OWNERSHIP,
		params,
	);
};
