import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderSetBlockToSaleParams extends IServiceBaseParams {
	id: number;
	blockToSaleAt: string;
}

/**
 * Sets blockToSaleAt
 * @param params - Parameters to set blockToSaleAt.
 * @returns A promise that resolves a sale order.
 */
export const saleOrderSetBlockToSaleService = (params: ISaleOrderSetBlockToSaleParams) => {
	return Api.post<ISaleOrder, ISaleOrderSetBlockToSaleParams>(SaleOrderEndPoints.SET_BLOCK_TO_SALE, params);
};
