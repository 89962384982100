export enum SaleOrderEndPoints {
	/**
	 * Insert a new vehicle sale order
	 * @host `/saleOrder/insert`
	 */
	INSERT = '/saleOrder/insert/',

	/**
	 * Search for vehicle sale orders
	 * @host `/saleOrder/search`
	 */
	SEARCH = '/saleOrder/search/',

	/**
	 * Get vehicle sale orders by id
	 * @host `/saleOrder/getById`
	 */
	GET_BY_ID = '/saleOrder/getById/',

	/**
	 * Set block to sale
	 * @host `/saleOrder/setBlockToSale`
	 */
	SET_BLOCK_TO_SALE = '/saleOrder/setBlockToSale/',
	/**
	 * Remove vehicles from a sale order
	 * @host `/saleOrder/removeUnsold`
	 */
	REMOVE_UNSOLD = '/saleOrder/removeUnsold/',

	/**
	 * Change the sale amount
	 * @host `/saleOrder/changeAmounts`
	 */
	CHANGE_AMOUNTS = '/saleOrder/changeAmounts/',

	/**
	 * Cancel the sale amount
	 * @host `/saleOrder/cancel`
	 */
	CANCEL = '/saleOrder/cancel/',

	/**
	 * Confirm deposit received
	 * @host `/saleOrder/confirmDepositReceived`
	 */
	CONFIRM_DEPOSIT_RECEIVED = '/saleOrder/confirmDepositReceived/',

	/**
	 * Start reconditioning
	 * @host `/saleOrder/startReconditioning`
	 */
	START_RECONDITIONING = '/saleOrder/startReconditioning/',

	/**
	 * Accept reconditioning
	 * @host `/saleOrder/acceptReconditioning`
	 */
	ACCEPT_RECONDITIONING = '/saleOrder/acceptReconditioning',

	/**
	 * Confirm reconditioning
	 * @host `/saleOrder/confirmReconditioning`
	 */
	CONFIRM_RECONDITIONING = '/saleOrder/confirmReconditioning/',

	/**
	 * Confirm payment received
	 * @host `/saleOrder/confirmPaymentReceived`
	 */
	CONFIRM_PAYMENT_RECEIVED = '/saleOrder/confirmPaymentReceived',

	/**
	 * Confirm delivery
	 * @host `/saleOrder/confirmDelivery`
	 */
	CONFIRM_DELIVERY = '/saleOrder/confirmDelivery',

	/**
	 * Confirm change of ownership
	 * @host `/saleOrder/confirmChangeOfOwnership`
	 */
	CONFIRM_CHANGE_OF_OWNERSHIP = '/saleOrder/confirmChangeOfOwnership',

	/**
	 * Confirm price
	 * @host `/saleOrder/confirmPrice`
	 */
	CONFIRM_PRICE = '/saleOrder/confirmPrice',
}
