import { BatchEndPoints } from './BatchEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBatch } from '../entities/Batch';
import type { IBatchLine } from '../entities/BatchLine';
import type { HoldingDateStartType } from '../types/HoldingDateStartType';
import type { HoldingStrategyType } from '../types/HoldingStrategyType';
import type { PaymentTriggerType } from '../types/PaymentTriggerType';

export interface IBatchInsertParams extends IServiceBaseParams {
	supplier: string;
	validFrom: string | Date;
	validTo: string | Date;
	withPlateNumber?: boolean;
	paymentTrigger?: PaymentTriggerType;
	paymentDays?: number;
	holdingDateStart?: HoldingDateStartType;
	holdingStartStrategy?: HoldingStrategyType;
	holdingEndStrategy?: HoldingStrategyType;
	returnParkingId?: number;
	lines?: IBatchLine[];
}

/**
 * Insert a batch
 * @return {Promise<BatchType>} `BatchType`
 */
export const batchInsertService = (model: IBatchInsertParams): Promise<ServiceResponse<IBatch>> => {
	return Api.post<IBatch, IBatchInsertParams>(BatchEndPoints.INSERT, model);
};
