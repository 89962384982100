import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const VehicleEngine = lazyWithRetry(() => import('./pages/VehicleEngine'));
const VehicleEngineNew = lazyWithRetry(() => import('./pages/VehicleEngineNew'));
const VehicleEngineModify = lazyWithRetry(() => import('./pages/VehicleEngineModify'));

export const vehicleEngineRoutes: { [key: number]: IRouteConfig } = {};

vehicleEngineRoutes[routesIdMap.FleetVehicleEngine] = {
	component: VehicleEngine,
	exact: true,
	id: routesIdMap.FleetVehicleEngine,
	name: 'Vehicle engine',
	path: '/fleet/vehicle-engine',
	permissions: [PermissionsType.VehicleEngine],
	icon: 'rocket',
	menuText: 'Engines',
	childrens: [routesIdMap.FleetVehicleEngineNew, routesIdMap.FleetVehicleEngineModify],
};

vehicleEngineRoutes[routesIdMap.FleetVehicleEngineNew] = {
	component: VehicleEngineNew,
	exact: true,
	id: routesIdMap.FleetVehicleEngineNew,
	name: 'New vehicle engine',
	path: '/fleet/vehicle-engine/new',
	permissions: [PermissionsType.VehicleEngineManagement],
	icon: 'plus',
	menuText: 'New vehicle engine',
};

vehicleEngineRoutes[routesIdMap.FleetVehicleEngineModify] = {
	component: VehicleEngineModify,
	exact: true,
	id: routesIdMap.FleetVehicleEngineModify,
	name: 'Modify vehicle engine',
	path: '/fleet/vehicle-engine/:id',
	permissions: [PermissionsType.VehicleEngineManagement],
};
