import { createSyncAction } from '../../../shared/state/createAction';
import { createAsyncAction } from '../../../shared/state/createAsyncAction';
import type { ISaleTicket } from '../entities/SaleTicket';
import type { ISaleTicketGetByPlateNumberParams } from '../services/SaleTicketGetByPlateNumber';
import { saleTicketGetByPlateNumberService } from '../services/SaleTicketGetByPlateNumber';

/**
 * Gets sale tickets by plate number
 * @param {ISaleTicketGetByPlateNumberParams} params - Parameters for the search of sale tickets.
 * @returns {ISaleTicket[]} payload - The sale tickets that match the search criteria.
 */
export const saleTicketGetByPlateNumber = createAsyncAction<ISaleTicket[], ISaleTicketGetByPlateNumberParams>(
	'saleTickets/getByPlateNumber',
	saleTicketGetByPlateNumberService,
);

/**
 * Clears sale tickets
 */
export const saleTicketsClear = createSyncAction('saleTickets/clear');
