import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const VehicleVersion = lazyWithRetry(() => import('~/features/fleet/vehicleVersion/pages/VehicleVersion'));
const VehicleVersionNew = lazyWithRetry(() => import('~/features/fleet/vehicleVersion/pages/VehicleVersionNew'));
const VehicleVersionModify = lazyWithRetry(() => import('~/features/fleet/vehicleVersion/pages/VehicleVersionModify'));

export const vehicleVersionRoutes: { [key: number]: IRouteConfig } = {};

vehicleVersionRoutes[routesIdMap.FleetVehicleVersion] = {
	component: VehicleVersion,
	exact: true,
	id: routesIdMap.FleetVehicleVersion,
	name: 'Vehicle versions',
	path: '/fleet/vehicle-version',
	permissions: [PermissionsType.VehicleVersion],
	icon: 'list-ol',
	menuText: 'Versions',
	childrens: [routesIdMap.FleetVehicleVersionNew, routesIdMap.FleetVehicleVersionModify],
};

vehicleVersionRoutes[routesIdMap.FleetVehicleVersionNew] = {
	component: VehicleVersionNew,
	exact: true,
	id: routesIdMap.FleetVehicleVersionNew,
	name: 'New version',
	path: '/fleet/vehicle-version/new',
	permissions: [PermissionsType.VehicleVersionManagement],
	isChild: true,
	icon: 'plus',
	menuText: 'New version',
};

vehicleVersionRoutes[routesIdMap.FleetVehicleVersionModify] = {
	component: VehicleVersionModify,
	exact: true,
	id: routesIdMap.FleetVehicleVersionModify,
	name: 'Modify vehicle version',
	path: '/fleet/vehicle-version/:id',
	isChild: true,
	icon: 'pencil',
	permissions: [PermissionsType.VehicleVersionManagement],
};
