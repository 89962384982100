import { useEffect, useRef } from 'react';

import type { FieldValues, Path } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';

export interface IFieldActionProps<T extends FieldValues> {
	// Name of the field to watch
	name: Path<T> | Array<Path<T>>;
	// Value to watch for (optional)
	becomes?: any | ((value: any) => boolean);
	// Name of the field or fields to set
	set: string | string[];
	// Value to set the field to
	to: any;
	// Additional update options
	options?: Partial<{
		shouldValidate: boolean;
		shouldDirty: boolean;
		shouldTouch: boolean;
	}>;
}

/**
 * FieldAction component watches a specified field value and sets other field values conditionally.
 *
 * @param {IFieldActionProps} props - The properties for the FieldAction component.
 * @param {string} props.name - Name of the field to watch.
 * @param {any} [props.becomes] - Value to watch for. If not provided, any value change triggers the action.
 * @param {string | string[]} props.set - Name of the field or fields to set.
 * @param {any} props.to - Value to set the field(s) to.
 * @param {Partial<{ shouldValidate: boolean, shouldDirty: boolean, shouldTouch: boolean }>} [props.options] - Additional update options.
 * @returns {null} This component does not render any JSX.
 */
export const FieldAction = <T extends FieldValues>({ name, becomes, set, to, options }: IFieldActionProps<T>): null => {
	const { setValue } = useFormContext();
	const result = useWatch({ name: name as Path<T> });

	const isMounted = useRef(false);

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true;
			return;
		}
		const values = Array.isArray(name)
			? name.reduce(
					(acc, fieldName, index) => {
						acc[fieldName] = result[index];
						return acc;
					},
					{} as Record<string, any>,
				)
			: { [name]: result };

		const shouldTrigger =
			becomes === undefined ||
			(typeof becomes === 'function'
				? becomes(values)
				: Object.values(values).some((value) => value === becomes));

		if (shouldTrigger) {
			const newValue = typeof to === 'function' ? to(values) : to ?? null;
			const fieldsToSet = Array.isArray(set) ? set : [set];
			fieldsToSet.forEach((fieldName) => {
				setValue(fieldName, newValue, options);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(result)]);

	return null;
};
