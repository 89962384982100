import { createSlice } from '@reduxjs/toolkit';

import type { IBatch } from '@crac/core/modules/fleet/batch/entities/Batch';
import {
	batchClear,
	batchDelete,
	batchInsert,
	batchModify,
	batchSearch,
} from '@crac/core/modules/fleet/batch/state/actions/BatchActions';
import type { IBatchStateType } from '@crac/core/modules/fleet/batch/state/stateType/BatchStateType';

const initialState: IBatchStateType = {
	batches: [],
	batchInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	batchSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	batchModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	batchDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const batchSlice = createSlice({
	name: 'BatchSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(batchClear, (state) => {
			return {
				...state,
				batches: [],
			};
		});
		// SEARCH
		builder
			.addCase(batchSearch.pending, (state) => {
				return {
					...state,
					batchSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(batchSearch.fulfilled, (state, action) => {
				return {
					...state,
					batchSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					batches: action.payload,
				};
			})
			.addCase(batchSearch.rejected, (state, action) => {
				return {
					...state,
					batchSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(batchInsert.pending, (state) => {
				return {
					...state,
					batchInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(batchInsert.fulfilled, (state, action) => {
				return {
					...state,
					batches: [...state.batches, action.payload],
					batchInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(batchInsert.rejected, (state, action) => {
				return {
					...state,
					batchInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(batchModify.pending, (state) => {
				return {
					...state,
					batchModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(batchModify.fulfilled, (state, action) => {
				const { code } = action.payload as IBatch;
				const batches = state.batches?.map((fine) => (fine.code === code ? { ...action.payload } : fine));
				return {
					...state,
					batches,
					batchModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(batchModify.rejected, (state, action) => {
				return {
					...state,
					batchModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(batchDelete.pending, (state) => {
				return {
					...state,
					batchDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(batchDelete.fulfilled, (state, action) => {
				const { code } = action.payload as IBatch;
				const batches = state.batches?.filter((fine) => fine.code !== code);
				return {
					...state,
					batches,
					batchDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(batchDelete.rejected, (state, action) => {
				return {
					...state,
					batchDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const batchReducer = batchSlice.reducer;
