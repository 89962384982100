import { useMemo } from 'react';

import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

export const checkEmployeePermissionByRoute = (currentRoute: IRouteConfig, userPermissions?: string[]) => {
	const hasUserPermissions = userPermissions && userPermissions.length > 0;
	const checkRoutePermissions = currentRoute.permissions && currentRoute.permissions.length > 0;

	let hasPermission = currentRoute.permissions.length === 0;

	if (checkRoutePermissions && hasUserPermissions) {
		for (const permission of currentRoute.permissions) {
			hasPermission = userPermissions.includes(permission);
			if (!hasPermission) {
				break;
			}
		}
	}

	return { hasPermission };
};

export const useCheckRoutePermission = (currentRoute: IRouteConfig, userPermissions?: string[]) => {
	const { hasPermission } = useMemo(
		() => checkEmployeePermissionByRoute(currentRoute, userPermissions),
		[currentRoute, userPermissions],
	);

	return {
		hasPermission,
	};
};
