import type { ElementType } from 'react';
import React from 'react';

import { useFormContext } from 'react-hook-form';

import type { IFieldLabelProps } from './FieldLabel';
import { FieldLabel } from './FieldLabel';

interface IFieldGroupProps extends Omit<IFieldLabelProps, 'label'> {
	label?: string | React.ReactNode;
	// The name of the field for the error message
	name: string;
	// Estra class name for the field group container
	className?: string;
	// The wrapper element for the field
	wrapper?: ElementType;
	// (not allowed in FieldGroup)
	innerRef?: React.RefObject<any>;
}

export const FieldGroup: React.FC<IFieldGroupProps> = ({ wrapper, children, ...props }) => {
	const { name, id, label, required, type, className, role } = props;

	const { getFieldState, formState } = useFormContext();
	const { error } = getFieldState(name, formState);

	const Wrapper = wrapper ?? FieldLabel;
	return (
		<Wrapper className={className} error={error} id={id} label={label} required={required} role={role} type={type}>
			{children}
		</Wrapper>
	);
};
