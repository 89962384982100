'use client';
import type { FC } from 'react';
import React from 'react';

import { CardFooter as RSCardFooter } from 'reactstrap';
import type { CardFooterProps as RSCardFooterProps } from 'reactstrap/types/lib/CardFooter';

export type CardFooterProps = RSCardFooterProps;

export const CardFooter: FC<CardFooterProps> = ({ children, ...props }) => {
	return <RSCardFooter {...props}>{children}</RSCardFooter>;
};
