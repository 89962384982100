import { createSlice } from '@reduxjs/toolkit';

import {
	vehicleVersionClear,
	vehicleVersionDelete,
	vehicleVersionInsert,
	vehicleVersionModify,
	vehicleVersionSearch,
} from '@crac/core/modules/fleet/vehicleVersion/state/actions/VehicleVersionActions';
import type { IVehicleVersionStateType } from '@crac/core/modules/fleet/vehicleVersion/state/stateType/VehicleVersionStateType';

const initialState: IVehicleVersionStateType = {
	vehicleVersions: [],
	vehicleVersionSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleVersionDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleVersionInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleVersionModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleVersionSlice = createSlice({
	name: 'VehicleVersionSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(vehicleVersionSearch.pending, (state) => {
				return {
					...state,
					vehicleVersionSearchRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleVersionSearch.rejected, (state, action) => {
				return {
					...state,
					vehicleVersionSearchRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleVersionSearch.fulfilled, (state, action) => {
				return {
					...state,
					vehicleVersionSearchRequest: { inProgress: false, messages: [], ok: true },
					vehicleVersions: action.payload,
				};
			});
		// INSERT
		builder
			.addCase(vehicleVersionInsert.pending, (state) => {
				return {
					...state,
					vehicleVersionInsertRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleVersionInsert.rejected, (state, action) => {
				return {
					...state,
					vehicleVersionInsertRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleVersionInsert.fulfilled, (state, action) => {
				return {
					...state,
					vehicleVersionInsertRequest: { inProgress: false, messages: [], ok: true },
					vehicleVersions: [...state.vehicleVersions, action.payload],
				};
			});
		// MODIFY
		builder
			.addCase(vehicleVersionModify.pending, (state) => {
				return {
					...state,
					vehicleVersionModifyRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleVersionModify.rejected, (state, action) => {
				return {
					...state,
					vehicleVersionModifyRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleVersionModify.fulfilled, (state, action) => {
				return {
					...state,
					vehicleVersionModifyRequest: { inProgress: false, messages: [], ok: true },
					vehicleVersions: state.vehicleVersions.map((item) =>
						item.id === action.payload.id ? action.payload : item,
					),
				};
			});
		// DELETE
		builder
			.addCase(vehicleVersionDelete.pending, (state) => {
				return {
					...state,
					vehicleVersionDeleteRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(vehicleVersionDelete.rejected, (state, action) => {
				return {
					...state,
					vehicleVersionDeleteRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			})
			.addCase(vehicleVersionDelete.fulfilled, (state, action) => {
				return {
					...state,
					vehicleVersionDeleteRequest: { inProgress: false, messages: [], ok: true },
					vehicleVersions: state.vehicleVersions.filter(({ id }) => id !== action.payload.id),
				};
			});
		// CLEAR
		builder.addCase(vehicleVersionClear, (state) => {
			return {
				...state,
				vehicleVersions: [],
			};
		});
	},
});

export const vehicleVersionReducer = vehicleVersionSlice.reducer;
