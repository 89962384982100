import { createSlice } from '@reduxjs/toolkit';

import {
	vehicleLineGetExternalRepairsByVehicle,
	vehicleLineGetForSaleByVehicle,
	vehicleLinesGetByBooking,
	vehicleLinesGetByVehicle,
	vehicleLinesGetLastByVehicle,
} from '@crac/core/redux/actions/VehicleLineActions';
import type { IVehicleLinesReducerState } from '@crac/core/redux-store/reducersState/vehicleLines';
const initialState: IVehicleLinesReducerState = {
	lastVehicleLine: { data: null, inProgress: false, messages: [], ok: true },
	vehicleLines: [],
	vehicleLinesRequest: { inProgress: false, messages: [], ok: true },
	vehicleLineGetExternalRepairsByVehicleRequest: { inProgress: false, messages: [], ok: true },
	vehicleLineGetForSaleByVehicleRequest: { inProgress: false, messages: [], ok: true },
};

const linesSlice = createSlice({
	name: 'VehicleLinesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY VEHICLE
		builder
			.addCase(vehicleLinesGetByVehicle.pending, (state) => {
				return {
					...state,
					vehicleLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLinesGetByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					vehicleLines: action.payload,
					vehicleLinesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLinesGetByVehicle.rejected, (state, action) => {
				return {
					...state,
					vehicleLinesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET LAST BY VEHICLE
		builder
			.addCase(vehicleLinesGetLastByVehicle.pending, (state) => {
				return {
					...state,
					lastVehicleLine: {
						data: null,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLinesGetLastByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					lastVehicleLine: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLinesGetLastByVehicle.rejected, (state, action) => {
				return {
					...state,
					lastVehicleLine: {
						data: null,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY BOOKING
		builder
			.addCase(vehicleLinesGetByBooking.pending, (state) => {
				return {
					...state,
					vehicleLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLinesGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					vehicleLines: action.payload,
					vehicleLinesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLinesGetByBooking.rejected, (state, action) => {
				return {
					...state,
					vehicleLinesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// VEHICLE LINE GET EXTERNAL REPAIRS BY VEHICLE
		builder
			.addCase(vehicleLineGetExternalRepairsByVehicle.pending, (state) => {
				return {
					...state,
					vehicleLineGetExternalRepairsByVehicleRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLineGetExternalRepairsByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					vehicleLineGetExternalRepairsByVehicleRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicleLines: action.payload,
				};
			})
			.addCase(vehicleLineGetExternalRepairsByVehicle.rejected, (state, action) => {
				return {
					...state,
					vehicleLineGetExternalRepairsByVehicleRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// GET FOR SALE BY VEHICLE
		builder
			.addCase(vehicleLineGetForSaleByVehicle.pending, (state) => {
				return {
					...state,
					vehicleLineGetForSaleByVehicleRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLineGetForSaleByVehicle.rejected, (state, action) => {
				return {
					...state,
					vehicleLineGetForSaleByVehicleRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(vehicleLineGetForSaleByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					vehicleLineGetForSaleByVehicleRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicleLines: action.payload,
				};
			});
	},
});

export const linesReducer = linesSlice.reducer;
