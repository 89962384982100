import classNames from 'classnames';
import type { FieldError } from 'react-hook-form';
import type { CSSObjectWithLabel } from 'react-select';

import type { ISelectOption } from './types';

const compareParsedValues = (optionValue: any, inputValue: any) => {
	if (typeof optionValue === 'number') {
		return optionValue === Number(inputValue);
	}
	if (typeof optionValue === 'string') {
		return optionValue === String(inputValue);
	}
	if (typeof optionValue === 'object') {
		return JSON.stringify(optionValue) === JSON.stringify(inputValue);
	}
	return optionValue === inputValue;
};

/**
 * getOptionsFromValue
 *
 * A utility function to get the corresponding options from the given values.
 *
 * @template T - The type of the value
 *
 * @param {T | T[]} values - The value or array of values to find the options for
 * @param {ISelectOption<T>[]} [options] - The array of available options
 *
 * @returns {ISelectOption<T> | ISelectOption<T>[] | null} The matched option(s) or null if no match is found
 */
export const getOptionsFromValue = <T>(
	values: T | T[],
	options?: ISelectOption<T>[],
): ISelectOption<T> | ISelectOption<T>[] | null => {
	if (values === null || values === undefined) {
		return null;
	}
	if (!options) {
		// If no options are provided, return the value as is
		if (Array.isArray(values)) {
			return values.map((value) => ({ label: String(value), value }));
		}
		return [{ label: String(values), value: values }];
	}
	// If options are provided, find the corresponding options for the given values
	if (Array.isArray(values)) {
		return options.filter((option) => values.some((value) => compareParsedValues(option.value, value)));
	}

	return options.find((option) => compareParsedValues(option.value, values)) ?? null;
};

export const hasOptionsForValue = <T>(values: T | T[], options?: ISelectOption<T>[]): boolean => {
	if (!options) {
		return false;
	}

	if (Array.isArray(values)) {
		return options.some((option) => values.some((value) => compareParsedValues(option.value, value)));
	}

	return options.some((option) => compareParsedValues(option.value, values));
};

type SelectStyles = {
	control: (baseStyles: CSSObjectWithLabel) => CSSObjectWithLabel;
};

const getSelectStyles = (baseSelectStyles?: Partial<SelectStyles>, error?: FieldError): SelectStyles => ({
	...baseSelectStyles,
	control: (baseStyles: CSSObjectWithLabel) => {
		const styles = {
			...(baseSelectStyles?.control ? baseSelectStyles.control(baseStyles) : baseStyles),
		};
		if (error) {
			styles.border = '1px solid #f66d6a';
		}
		return styles;
	},
});

interface ComponentClassNameParams {
	id: string;
	error?: FieldError;
	defaultClassName?: string;
	defaultStyles?: Partial<SelectStyles>;
}

export const computeComponentClassName = ({ id, error, defaultClassName, defaultStyles }: ComponentClassNameParams) => {
	const className = classNames(defaultClassName, {
		'is-invalid': Boolean(error),
	});

	return {
		className,
		'aria-describedby': error ? `${id}-invalid-feedback` : undefined,
		styles: getSelectStyles(defaultStyles, error),
	};
};

type AnyFunction = (...args: any[]) => void;

export const debounce = <T extends AnyFunction>(func: T, wait: number = 500): T => {
	// eslint-disable-next-line init-declarations
	let timeoutId: ReturnType<typeof setTimeout> | undefined;
	return ((...args: Parameters<T>) => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(() => func(...args), wait);
	}) as T;
};
