import type { ElementType } from 'react';
import React, { useMemo } from 'react';

import type { OptionProps } from 'react-select';

import { AsyncSelectField } from './ASyncSelectField';
import { CreatableSelectField } from './CreatableSelectField';
import { CustomSelectOption } from './CustomSelectOption';
import { SyncSelectField } from './SyncSelectField';
import type { ISelectCreatableProps, ISelectFieldProps } from './types';
import { FieldGroup } from '../fieldGroup';
import { useValidateField } from '../hooks';

type SelectFieldType<T> = {
	// Trigger validations on load
	validateOnLoad?: boolean;
	// Custom wrapper for component
	wrapper?: ElementType;
} & (ISelectFieldProps<T> | ISelectCreatableProps<T>);

/**
 * Select field component
 * @param props - The properties for the select field
 * @returns The rendered select field component
 */
export const SelectField = <T,>({ validateOnLoad, wrapper, renderOption, ...rest }: SelectFieldType<T>) => {
	const components = useMemo(() => {
		if (renderOption) {
			return {
				// eslint-disable-next-line react/no-unstable-nested-components
				Option: (props: OptionProps<any, boolean, any>) => (
					<CustomSelectOption {...props} renderOption={renderOption} />
				),
			};
		}
		return undefined;
	}, [renderOption]);

	const props = {
		...rest,
		components,
		id: rest.id ?? rest.name,
		required: Boolean(rest.validation?.required) || rest.required,
	};

	useValidateField({ ...props, validateOnLoad });

	const { loadOptions, isCreatable } = props as ISelectCreatableProps<T>;

	let element = null;
	if (isCreatable) {
		element = <CreatableSelectField {...props} isCreatable />;
	} else if (loadOptions) {
		element = <AsyncSelectField {...props} />;
	} else {
		element = <SyncSelectField {...props} />;
	}

	return (
		<FieldGroup {...props} id={props.id ?? props.name} wrapper={wrapper}>
			{element}
		</FieldGroup>
	);
};
