import { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { cityReset } from '@crac/core/redux/actions/CityActions';
import { provinceReset } from '@crac/core/redux/actions/ProvinceActions';

import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';

const actionsImported = {
	cityReset,
	provinceReset,
};

interface IUseSupplierFormCancel {
	resetAddress?: boolean;
	cancelRoute?: string;
}

export const useSupplierFormCancel = ({ resetAddress, cancelRoute }: IUseSupplierFormCancel) => {
	const navigate = useNavigate();
	const { cityReset, provinceReset } = useBindAndMemoizeActions(actionsImported);

	const handleCancel = useCallback(() => {
		if (resetAddress) {
			cityReset();
			provinceReset();
		}

		if (cancelRoute) {
			navigate(`${cancelRoute}${location.search}`);

			return;
		}

		navigate(-1);
	}, [cancelRoute, cityReset, navigate, provinceReset, resetAddress]);

	return { handleCancel };
};
