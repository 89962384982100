import React, { useEffect } from 'react';

import { useWatch } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import { debounce, getOptionsFromValue, hasOptionsForValue } from './helpers';
import { useSelectFieldProps } from './hooks';
import { SelectController } from './SelectController';
import { SelectNoneController } from './SelectNoneController';
import type { ISelectFieldProps, ISelectOption } from './types';

export const AsyncSelectField = <T,>({ onChange, ...rest }: ISelectFieldProps<T>) => {
	const {
		// Custom component or default 'AsyncSelect' element
		component: Component = AsyncSelect,
		...props
	} = useSelectFieldProps(rest);

	const value = useWatch({ name: rest.name });

	const [loadedOptions, setLoadedOptions] = React.useState<ISelectOption<T>[]>([]);

	/**
	 * Intercepts the loadOptions function to store the loaded options in state
	 */
	const handleLoadOptions = async (inputValue: T, loadOptions = rest.loadOptions) => {
		if (!loadOptions || !inputValue) {
			return null;
		}
		// Load options asynchronously
		const loadedOptions = await loadOptions(inputValue as string);
		if (!loadedOptions) {
			return null;
		}
		// Store the loaded options in state without duplicates
		setLoadedOptions((current) => {
			const currentMap = new Map(current.map((option) => [option.value, option]));
			const newOptions: ISelectOption<T>[] = [];
			for (const option of loadedOptions) {
				if (!currentMap.has(option.value)) {
					newOptions.push(option);
				}
			}
			if (newOptions.length === 0) {
				return current;
			}
			return [...current, ...newOptions];
		});
		// Return the loaded options
		return loadedOptions;
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debounceLoadOptions = debounce(async (input: T, callback: any) => {
		const options = await handleLoadOptions(input, rest.loadOptions);
		if (options) {
			callback(options);
		}
	});

	useEffect(() => {
		if (!value) {
			return;
		}
		if (!hasOptionsForValue(value, loadedOptions)) {
			if (Array.isArray(value)) {
				value.forEach((item) => handleLoadOptions(item, rest.loadDefaultOptions));
			} else {
				handleLoadOptions(value, rest.loadDefaultOptions);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, loadedOptions]);

	return (
		<SelectController {...rest} onChange={onChange} registerOptions={rest.validation}>
			{(field) => (
				<SelectNoneController {...rest} field={field}>
					{({ disabled }) => (
						<Component
							{...props}
							{...field}
							cacheOptions
							disabled={props.isDisabled || disabled}
							getOptionLabel={(option: ISelectOption<T>) => option.label}
							getOptionValue={(option: ISelectOption<T>) => option.value}
							isDisabled={props.isDisabled || disabled}
							loadOptions={debounceLoadOptions}
							value={getOptionsFromValue(field.value, loadedOptions)}
						/>
					)}
				</SelectNoneController>
			)}
		</SelectController>
	);
};
