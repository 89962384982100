import {
	extraDriverServiceGetByBooking,
	extraDriverServiceGetPrevisionServices,
	extraDriverServiceGetServices,
	extraDriverServiceRemove,
	extraDriverServiceSave,
} from '../../data/services/ExtraDriverService';
import type { IExtraDriver } from '../../models/entities/ExtraDriver';
import type { IExtraDriverGetPrevisionServices, IService } from '../../models/entities/Service';
import type {
	IExtraDriverGetByBookingParams,
	IExtraDriverGetPrevisionServicesParams,
	IExtraDriverGetServicesParams,
	IExtraDriverRemoveParams,
	IExtraDriverSaveParams,
} from '../../models/serviceParams/ExtraDriverParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves extra driver details associated with a specific booking.
 *
 * @param {IExtraDriver[]} payload - The extra drivers to be retrieved.
 * @param {IExtraDriverGetByBookingParams} params - Parameters for identifying extra drivers related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of extra drivers.
 */
export const extraDriverGetByBooking = createAsyncAction<IExtraDriver[], IExtraDriverGetByBookingParams>(
	'extraDriver/getByBooking',
	extraDriverServiceGetByBooking,
);

/**
 * Retrieves services associated with extra drivers.
 *
 * @param {IExtraDriverGetPrevisionServices} payload - The services to be retrieved.
 * @param {IExtraDriverGetPrevisionServicesParams} params - Parameters for identifying services related to extra drivers.
 * @returns {Promise<void>} - A promise that resolves to an array of services.
 */
export const extraDriverGetPrevisionServices = createAsyncAction<
	IExtraDriverGetPrevisionServices,
	IExtraDriverGetPrevisionServicesParams
>('extraDriver/getPrevisionServices', extraDriverServiceGetPrevisionServices);

/**
 * Saves details of an extra driver.
 *
 * @param {IExtraDriver} payload - The extra driver to be saved.
 * @param {IExtraDriverSaveParams} params - Parameters for saving the extra driver.
 * @returns {Promise<void>} - A promise that resolves after the extra driver is saved.
 */
export const extraDriverSave = createAsyncAction<IExtraDriver[], IExtraDriverSaveParams>(
	'extraDriver/save',
	extraDriverServiceSave,
);

/**
 * Removes an extra driver.
 *
 * @param {IExtraDriver} payload - The extra driver to be removed.
 * @param {IExtraDriverRemoveParams} params - Parameters for removing the extra driver.
 * @returns {Promise<void>} - A promise that resolves after the extra driver is removed.
 */
export const extraDriverRemove = createAsyncAction<IExtraDriver[], IExtraDriverRemoveParams>(
	'extraDriver/remove',
	extraDriverServiceRemove,
);

/**
 * Clears the extra driver state.
 */
export const extraDriverClear = createSyncAction('extraDriver/clear');

/**
 * Clears the extra driver services state.
 */
export const extraDriverServicesClear = createSyncAction('extraDriver/servicesClear');

/**
 * Retrieves services associated with extra drivers for a specific booking.
 *
 * @param {IService[]} payload - The services to be retrieved.
 * @param {IExtraDriverGetServicesParams} params - Parameters for identifying services related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of services.
 */
export const extraDriverGetServices = createAsyncAction<IService[], IExtraDriverGetServicesParams>(
	'extraDriver/getServices',
	extraDriverServiceGetServices,
);
