import { VehicleVersionEndpoints } from './VehicleVersionEndPoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../../modules/shared/types/ServiceBaseParams';
import type { IVehicleVersion } from '../entities/VehicleVersion';

export interface IVehicleVersionSearchParams extends IServiceBaseParams {
	brand?: string;
	metaModelId?: number;
	name?: string;
}

export interface IVehicleVersionInsertParams extends IServiceBaseParams {
	name: string;
	metaModelId: number;
}

export interface IVehicleVersionModifyParams extends IServiceBaseParams {
	id: number;
	name: string;
	metaModelId: number;
}

export interface IVehicleVersionDeleteParams extends IServiceBaseParams {
	id: number;
}

/**
 * Search vehicle versions by name
 * @param {model} IVehicleVersionSearchParams
 * @returns {Promise<IVehicleVersion[]>} Vehicle version list
 */
export const vehicleVersionSearchService = (model: IVehicleVersionSearchParams) => {
	return Api.get<IVehicleVersion[], IVehicleVersionSearchParams>(VehicleVersionEndpoints.SEARCH, model);
};

/**
 * Insert a vehicle version
 * @param {model} IVehicleVersionInsertParams
 * @returns {Promise<IVehicleVersion>} Vehicle version object
 */
export const vehicleVersionInsertService = (model: IVehicleVersionInsertParams) => {
	return Api.post<IVehicleVersion, IVehicleVersionInsertParams>(VehicleVersionEndpoints.INSERT, model);
};

/**
 * Modify a vehicle version
 * @param {model} IVehicleVersion
 * @returns {Promise<IVehicleVersion>} Vehicle version object
 */
export const vehicleVersionModifyService = (model: IVehicleVersionModifyParams) => {
	return Api.post<IVehicleVersion, IVehicleVersionModifyParams>(VehicleVersionEndpoints.MODIFY, model);
};

/**
 * Delete a vehicle version by id
 * @param {model} IVehicleVersion
 * @returns {Promise<IVehicleVersion>} Vehicle version object
 */
export const vehicleVersionDeleteService = (model: IVehicleVersionDeleteParams) => {
	return Api.post<IVehicleVersion, IVehicleVersionDeleteParams>(VehicleVersionEndpoints.DELETE, model);
};
