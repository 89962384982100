export enum BatchEndPoints {
	/**
	 * Batch search
	 * @host `/batch/search/`
	 */
	SEARCH = '/batch/search/',

	/**
	 * Batch insert
	 * @host `/batch/insert/`
	 */
	INSERT = '/batch/insert/',

	/**
	 * Batch modify
	 * @host `/batch/modify/`
	 */
	MODIFY = '/batch/modify/',

	/**
	 * Batch delete
	 * @host `/batch/delete/`
	 */
	DELETE = '/batch/delete/',
}
