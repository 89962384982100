import { styled } from 'styled-components';

export const FieldsetStyles = styled.fieldset`
	position: relative;
	border-top: 1px solid #c2cfd6;
	margin-bottom: 30px;
	margin-top: 15px;
`;
export const FielSetLegendStyles = styled.legend`
	margin-top: -15px;
	margin-left: 5px;
	padding-left: 7px;
	padding-right: 7px;
	background-color: #fff;
	max-width: fit-content;
	font-size: medium;
	font-weight: 500;
`;
export const FieldsetAllBordersStyles = styled.fieldset`
	position: relative;
	border: 1px solid #c2cfd6;
	margin-bottom: 30px;
	margin-top: 15px;
	padding-left: 15px;
	padding-right: 15px;
`;
