import { createAction } from '@reduxjs/toolkit';

import { createAsyncAction } from '../../../shared/state/createAsyncAction';
import type { ISaleOrder } from '../entities/SaleOrder';
import type { ISaleOrderLine } from '../entities/SaleOrderLine';
import type { ISaleOrderAcceptReconditioningParams } from '../services/SaleOrderAcceptReconditioningService';
import { saleOrderServiceAcceptReconditioning } from '../services/SaleOrderAcceptReconditioningService';
import type { ISaleOrderCancelParams } from '../services/SaleOrderCancelService';
import { saleOrderCancelService } from '../services/SaleOrderCancelService';
import type { ISaleOrderChangeAmountsParams } from '../services/SaleOrderChangeAmountsService';
import { saleOrderChangeAmountsService } from '../services/SaleOrderChangeAmountsService';
import type { ISaleOrderConfirmChangeOfOwnershipParams } from '../services/SaleOrderConfirmChangeOfOwnershipService';
import { saleOrderServiceConfirmChangeOfOwnership } from '../services/SaleOrderConfirmChangeOfOwnershipService';
import type { ISaleOrderConfirmDeliveryParams } from '../services/SaleOrderConfirmDeliveryService';
import { saleOrderConfirmDeliveryService } from '../services/SaleOrderConfirmDeliveryService';
import type { ISaleOrderConfirmDepositReceivedParams } from '../services/SaleOrderConfirmDepositReceivedService';
import { saleOrderConfirmDepositReceivedService } from '../services/SaleOrderConfirmDepositReceivedService';
import type { ISaleOrderConfirmPaymentReceivedParams } from '../services/SaleOrderConfirmPaymentReceivedService';
import { saleOrderServiceConfirmPaymentReceived } from '../services/SaleOrderConfirmPaymentReceivedService';
import type { ISaleOrderConfirmPriceParams } from '../services/SaleOrderConfirmPriceService';
import { saleOrderConfirmPriceService } from '../services/SaleOrderConfirmPriceService';
import type { ISaleOrderConfirmReconditioningParams } from '../services/SaleOrderConfirmReconditioningService';
import { saleOrderConfirmReconditioningService } from '../services/SaleOrderConfirmReconditioningService';
import type { ISaleOrderGetByIdParams } from '../services/SaleOrderGetByIdService';
import { saleOrderGetByIdService } from '../services/SaleOrderGetByIdService';
import type { ISaleOrderInsertParams } from '../services/SaleOrderInsertService';
import { saleOrderInsertService } from '../services/SaleOrderInsertService';
import type { ISaleOrderLineGetByOrderIdParams } from '../services/SaleOrderLineGetByOrderIdService';
import { saleOrderLineGetByOrderIdService } from '../services/SaleOrderLineGetByOrderIdService';
import type { ISaleOrderRemoveUnsoldParams } from '../services/SaleOrderRemoveUnsoldService';
import { saleOrderRemoveUnsoldService } from '../services/SaleOrderRemoveUnsoldService';
import { type ISaleOrderSearchParams, saleOrderSearchService } from '../services/SaleOrderSearchService';
import type { ISaleOrderSetBlockToSaleParams } from '../services/SaleOrderSetBlockToSaleService';
import { saleOrderSetBlockToSaleService } from '../services/SaleOrderSetBlockToSaleService';
import type { ISaleOrderStartReconditioningParams } from '../services/SaleOrderStartReconditioningService';
import { saleOrderStartReconditioningService } from '../services/SaleOrderStartReconditioningService';

/**
 * Inserts a new vehicle sale order.
 * @param {ISaleOrder} payload - The sale order to be inserted.
 * @param {ISaleOrderInsertParams} params - Parameters for the insertion of the sale order.
 * @returns {Promise<void>} - A promise that resolves to the inserted sale order.
 */
export const saleOrderInsert = createAsyncAction<ISaleOrder, ISaleOrderInsertParams>(
	'saleOrder/insert',
	saleOrderInsertService,
);

/**
 * Searches for vehicle sale orders.
 * @param {ISaleOrder[]} payload - The sale orders that match the search criteria.
 * @param {ISaleOrderSearchParams} params - Parameters for the search of sale orders.
 * @returns {Promise<void>} - A promise that resolves to the sale orders that match the search criteria.
 */
export const saleOrderSearch = createAsyncAction<ISaleOrder[], ISaleOrderSearchParams>(
	'saleOrder/search',
	saleOrderSearchService,
);

/**
 * Clears the vehicle sale order state.
 * @returns {void}
 */
export const saleOrderClear = createAction('saleOrder/clear');

/**
 * Gets the vehicle sale order lines by order id.
 * @param {ISaleOrderLine[]} payload - The sale order lines that match the order id.
 * @param {ISaleOrderLineGetByOrderIdParams} params - Parameters for the search of sale order lines.
 * @returns {Promise<void>} - A promise that resolves to the sale order lines that match the order id.
 */
export const saleOrderLineGetByOrderId = createAsyncAction<ISaleOrderLine[], ISaleOrderLineGetByOrderIdParams>(
	'saleOrderLines/getByOrderId',
	saleOrderLineGetByOrderIdService,
);

/**
 * Gets a vehicle sale order by id.
 * @param {ISaleOrder} payload - The sale order that matches the id.
 * @param {ISaleOrderGetByIdParams} params - Parameters for the search of the sale order.
 * @returns {Promise<void>} - A promise that resolves to the sale order that matches the id.
 */
export const saleOrderGetById = createAsyncAction<ISaleOrder, ISaleOrderGetByIdParams>(
	'saleOrder/getById',
	saleOrderGetByIdService,
);

/**
 * Sets blockToSaleAt
 * @param {ISaleOrder} payload - The sale order.
 * @param {ISaleOrderSetBlockToSaleParams} params - Parameters to set blockToSaleAt.
 * @returns {Promise<void>} - A promise that resolves the sale order.
 */
export const saleOrderSetBlockToSale = createAsyncAction<ISaleOrder, ISaleOrderSetBlockToSaleParams>(
	'saleOrder/setBlockToSale',
	saleOrderSetBlockToSaleService,
);

/**
 * Removes vehicles from a sale order
 * @param {ISaleOrder} payload - The sale order.
 * @param {ISaleOrderRemoveUnsoldParams} params - Parameters to remove vehicles from a sale order.
 * @returns {Promise<void>} - A promise that resolves the sale order.
 */
export const saleOrderRemoveUnsold = createAsyncAction<ISaleOrder, ISaleOrderRemoveUnsoldParams>(
	'saleOrder/removeUnsold',
	saleOrderRemoveUnsoldService,
);

/**
 * Changes sale amount
 * @param {ISaleOrder} payload - The sale order.
 * @param {ISaleOrderChangeAmountsParams} params - Parameters to change sale amount.
 * @returns {Promise<void>} - A promise that resolves the sale order.
 */
export const saleOrderChangeAmounts = createAsyncAction<ISaleOrder, ISaleOrderChangeAmountsParams>(
	'saleOrder/changeAmounts',
	saleOrderChangeAmountsService,
);

/**
 * Cancels sale amount
 * @param {ISaleOrder} payload - The sale order.
 * @param {ISaleOrderCancelParams} params - Parameters to cancel a sale amount.
 * @returns {Promise<void>} - A promise that resolves the sale order.
 */
export const saleOrderCancel = createAsyncAction<ISaleOrder, ISaleOrderCancelParams>(
	'saleOrder/cancel',
	saleOrderCancelService,
);

/**
 * Confirms deposit is received
 * @param {ISaleOrder} payload - The sale order.
 * @param {ISaleOrderConfirmDepositReceivedParams} params - Parameters to confirm deposit is received
 * @returns {Promise<void>} - A promise that resolves the sale order.
 */
export const saleOrderConfirmDepositReceived = createAsyncAction<ISaleOrder, ISaleOrderConfirmDepositReceivedParams>(
	'saleOrder/confirmDepositReceived',
	saleOrderConfirmDepositReceivedService,
);

/**
 * Starts reconditioning
 * @param {ISaleOrder} payload - The sale order.
 * @param {ISaleOrderStartReconditioningParams} params - Parameters to start reconditioning.
 * @returns {Promise<void>} - A promise that resolves the sale order.
 */
export const saleOrderStartReconditioning = createAsyncAction<ISaleOrder, ISaleOrderStartReconditioningParams>(
	'saleOrder/startReconditioning',
	saleOrderStartReconditioningService,
);

/**
 * Accept reconditioning
 * @param {ISaleOrderAcceptReconditioningParams} params - Parameters to accept reconditioning
 * @param {ISaleOrder} payload - The sale order
 * @return {Promise<void>} - A promise that resolves the sale order
 */
export const saleOrderAcceptReconditioning = createAsyncAction<ISaleOrder, ISaleOrderAcceptReconditioningParams>(
	'saleOrder/acceptReconditioning',
	saleOrderServiceAcceptReconditioning,
);

/**
 * Confirms reconditioning
 * @param {ISaleOrder} payload - The sale order.
 * @param {ISaleOrderConfirmReconditioningParams} params - Parameters to confirm reconditioning.
 * @returns {Promise<void>} - A promise that resolves the sale order.
 */
export const saleOrderConfirmReconditioning = createAsyncAction<ISaleOrder, ISaleOrderConfirmReconditioningParams>(
	'saleOrder/confirmReconditioning',
	saleOrderConfirmReconditioningService,
);

/**
 * Confirm payment received
 * @param {ISaleOrderConfirmPaymentReceivedParams} params - Parameters to confirm payment
 * @param {ISaleOrder} payload - The sale order
 * @return {Promise<void>} - A promise that resolves the sale order
 */
export const saleOrderConfirmPaymentReceived = createAsyncAction<ISaleOrder, ISaleOrderConfirmPaymentReceivedParams>(
	'saleOrder/confirmPaymentReceived',
	saleOrderServiceConfirmPaymentReceived,
);

/**
 * Confirm delivery received
 * @param {ISaleOrderConfirmDeliveryParams} params - Parameters to confirm delivery
 * @param {ISaleOrder} payload - The sale order
 * @return {Promise<void>} - A promise that resolves the sale order
 */
export const saleOrderConfirmDelivery = createAsyncAction<ISaleOrder, ISaleOrderConfirmDeliveryParams>(
	'saleOrder/confirmDelivery',
	saleOrderConfirmDeliveryService,
);

/**
 * Confirm change of ownership
 * @param {ISaleOrderConfirmPaymentReceivedParams} params - Parameters to confirm change of ownership
 * @param {ISaleOrder} payload - The sale order
 * @return {Promise<void>} - A promise that resolves the sale order
 */
export const saleOrderConfirmChangeOfOwnership = createAsyncAction<
	ISaleOrder,
	ISaleOrderConfirmChangeOfOwnershipParams
>('saleOrder/confirmChangeOfOwnership', saleOrderServiceConfirmChangeOfOwnership);

/**
 * Confirms price
 * @param {ISaleOrderConfirmPriceParams} params - Parameters to confirms price
 * @param {ISaleOrder} payload - The sale order
 * @return {Promise<void>} - A promise that resolves the sale order
 */
export const saleOrderConfirmPrice = createAsyncAction<ISaleOrder, ISaleOrderConfirmPriceParams>(
	'saleOrder/confirmPrice',
	saleOrderConfirmPriceService,
);
