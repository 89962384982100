import { useFormState } from 'react-hook-form';

interface IFormState {
	children: (formState: ReturnType<typeof useFormState>) => React.ReactNode;
}

export const FormState = ({ children }: IFormState) => {
	const formState = useFormState();
	return children(formState);
};
