import type { FC } from 'react';
import { useMemo } from 'react';

import { formatSelect } from '@crac/core/helpers/commons';
import type { IPaymentTerm } from '@crac/core/models/entities/PaymentTerm';
import { SelectField } from '@crac/form/field';

import { usePaymentTermSelector } from '~/features/shared/state/selectors/PaymentTermSelector';

interface ISelectPaymentTermProps {
	name: string;
	label: string;
	paymentTerms?: IPaymentTerm[];
	required?: boolean;
	isClearable?: boolean;
}

export const SelectPaymentTerm: FC<ISelectPaymentTermProps> = ({ paymentTerms, ...rest }) => {
	const { paymentTerms: defaultPaymentTerms } = usePaymentTermSelector();

	const paymentTermsOptions = useMemo(
		() => formatSelect('description', 'code', paymentTerms || defaultPaymentTerms),
		[paymentTerms, defaultPaymentTerms],
	);

	return <SelectField options={paymentTermsOptions} {...rest} />;
};
