import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

type VehicleType = {
	plateNumber: string;
	amount: number;
};

export interface ISaleOrderChangeAmountsParams extends IServiceBaseParams {
	id: number;
	vehicles: VehicleType[];
}

/**
 * Change sale amount
 * @param params - Parameters to change sale amount.
 * @returns A promise that resolves a sale order.
 */
export const saleOrderChangeAmountsService = (params: ISaleOrderChangeAmountsParams) => {
	return Api.post<ISaleOrder, ISaleOrderChangeAmountsParams>(SaleOrderEndPoints.CHANGE_AMOUNTS, params);
};
