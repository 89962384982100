import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderCancelParams extends IServiceBaseParams {
	id: number;
}

/**
 * Cancels sale order
 * @param params - Parameters to cancel a sale order.
 * @returns A promise that resolves a sale order.
 */
export const saleOrderCancelService = (params: ISaleOrderCancelParams) => {
	return Api.post<ISaleOrder, ISaleOrderCancelParams>(SaleOrderEndPoints.CANCEL, params);
};
