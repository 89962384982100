import { VehicleEngineEndPoints } from './VehicleEngineEndPoints';
import type { IVehicleEngineInsertParams } from './VehicleEngineInsertService';
import { Api } from '../../../shared/api';
import type { IVehicleEngine } from '../entities/VehicleEngine';

export interface IVehicleEngineModifyParams extends IVehicleEngineInsertParams {
	id: number;
}

export const vehicleEngineServiceModify = (params: IVehicleEngineModifyParams) => {
	return Api.post<IVehicleEngine, IVehicleEngineModifyParams>(VehicleEngineEndPoints.MODIFY, params);
};
