import { SupplierEndPoints } from './SupplierEndPoints';
import { Api } from '../../shared/api';
import type { IServiceBaseParams } from '../../shared/types/ServiceBaseParams';
import type { ISupplier } from '../entities/Supplier';

export interface ISupplierGetAllParams extends IServiceBaseParams {
	plateNumber: string;
}

/**
 * Get supplier by branch code
 *  {string} branchCode Branch ccreationMethoode
 * @return {Promise} `Array<SupplierPropType>`
 */
export const supplierServiceGetAll = (model: ISupplierGetAllParams) =>
	Api.get<ISupplier[], ISupplierGetAllParams>(SupplierEndPoints.GET_ALL, model);
