import React from 'react';

import Select from 'react-select';

import { getOptionsFromValue } from './helpers';
import { useSelectFieldProps } from './hooks';
import { SelectAllController } from './SelectAllController';
import { SelectController } from './SelectController';
import { SelectNoneController } from './SelectNoneController';
import type { ISelectFieldProps, ISelectOption } from './types';

export const SyncSelectField = <T,>({ onChange, ...rest }: ISelectFieldProps<T>) => {
	const {
		// Custom component or default 'Select' element
		component: Component = Select,
		...props
	} = useSelectFieldProps(rest);

	const renderLabel = (label: string, icon?: string) => {
		if (!icon) {
			return label;
		}
		return (
			<>
				<i className={`me-1 fa fa-fw fa-${icon}`} />
				{label}
			</>
		);
	};

	return (
		<SelectController {...rest} onChange={onChange} registerOptions={rest.validation}>
			{(field) => (
				<SelectAllController {...rest} field={field}>
					<SelectNoneController {...rest} field={field}>
						{({ disabled }) => (
							<Component
								{...props}
								{...field}
								disabled={props.isDisabled || disabled}
								getOptionLabel={(item: ISelectOption<T>) => renderLabel(item.label, item.icon)}
								getOptionValue={(item: ISelectOption<T>) => item.value}
								isDisabled={props.isDisabled || disabled}
								value={getOptionsFromValue(field.value, rest.options)}
							/>
						)}
					</SelectNoneController>
				</SelectAllController>
			)}
		</SelectController>
	);
};
