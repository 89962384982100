import type { FC } from 'react';

import { Col } from '@crac/components/external/reactstrap/col';
import { Row } from '@crac/components/external/reactstrap/row';
import { Fieldset } from '@crac/components/intranet/shared/fieldset/Fieldset';
import { removeKeyWithEmptyString } from '@crac/core/helpers/commons';
import { InputField } from '@crac/form/field';
import { isEmail } from '@crac/form/validators/isEmail';

import { SupplierFormAddressFields } from './components/SupplierFormAddressFields';
import { SupplierFormBasicFields } from './components/SupplierFormBasicFields';
import { SupplierFormContentWrapper } from './components/SupplierFormContentWrapper';
import { SupplierFormSelectPaymentMethod } from './components/SupplierFormSelectPaymentMethod';
import { SelectPaymentTerm } from './components/SupplierFormSelectPaymentTerm';
import type { ISupplierFormContentProps } from './types';

export const SupplierFormContent: FC<ISupplierFormContentProps> = ({
	initialValues,
	onCancel,
	onSubmit,
	supplierType,
	title,
	fields,
}) => {
	if (initialValues) {
		removeKeyWithEmptyString(initialValues);
	}

	return (
		<SupplierFormContentWrapper
			initialValues={initialValues}
			onCancel={onCancel}
			onSubmit={onSubmit}
			supplierType={supplierType}
			title={title}
		>
			<SupplierFormBasicFields fields={fields} initialValues={initialValues} supplierType={supplierType} />

			{fields?.address ? (
				<SupplierFormAddressFields initialValues={initialValues} supplierType={supplierType} />
			) : null}

			{fields?.contact ? (
				<Fieldset legend="Contact information">
					<>
						<Row>
							<Col>
								<InputField
									id="email"
									label="Email"
									name="email"
									validateOnLoad={Boolean(initialValues)}
									validation={{
										required: true,
										validate: { email: isEmail() },
										maxLength: 50,
									}}
								/>
							</Col>
						</Row>
						<Row>
							<Col lg={6}>
								<InputField
									id="contact"
									label="Contact"
									name="contact"
									validateOnLoad={Boolean(initialValues)}
									validation={{
										required: true,
										maxLength: 50,
									}}
								/>
							</Col>
							<Col lg={6}>
								<InputField
									id="phone"
									label="Phone"
									name="phone"
									validateOnLoad={Boolean(initialValues)}
									validation={{
										required: true,
										maxLength: 30,
									}}
								/>
							</Col>
						</Row>
					</>
				</Fieldset>
			) : null}

			{fields?.paymentTerms ? (
				<Fieldset className="my-0" legend="Billing information">
					<Row>
						<Col lg={6}>
							<SelectPaymentTerm isClearable label="Payment term" name="paymentTermCode" />
						</Col>
						<Col lg={6}>
							<SupplierFormSelectPaymentMethod
								isClearable
								label="Payment method"
								name="paymentMethodCode"
							/>
						</Col>
					</Row>
				</Fieldset>
			) : null}
		</SupplierFormContentWrapper>
	);
};
