import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderConfirmPaymentReceivedParams extends IServiceBaseParams {
	id: number;
}

export const saleOrderServiceConfirmPaymentReceived = (params: ISaleOrderConfirmPaymentReceivedParams) => {
	return Api.post<ISaleOrder, ISaleOrderConfirmPaymentReceivedParams>(
		SaleOrderEndPoints.CONFIRM_PAYMENT_RECEIVED,
		params,
	);
};
