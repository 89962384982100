'use client';
import type { FC } from 'react';
import React from 'react';

import { Row as RSRow } from 'reactstrap';
import type { RowProps as RSRowProps } from 'reactstrap/types/lib/Row';

export type RowProps = RSRowProps;

export const Row: FC<RowProps> = ({ children, ...props }) => {
	return <RSRow {...props}>{children}</RSRow>;
};
