import type { Dispatch } from 'redux';

import { postCodeServiceGetByCountryAndText } from '@crac/core/data/services/PostCodeService';
import type { ISelectOptionType } from '@crac/core/models/types/SelectOptionType';
import { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import { cityGetByCountryAndPostCode, cityReset } from '@crac/core/redux/actions/CityActions';
import { provinceGetByCountryAndPostCode, provinceReset } from '@crac/core/redux/actions/ProvinceActions';

export const handlePostalCodeTextChange =
	(countryCode?: string) =>
	async ({ label }: ISelectOptionType) => {
		if (label && label.length >= 3 && countryCode) {
			const response = await postCodeServiceGetByCountryAndText({
				countryCode,
				creationMethod: CreationMethod.BRANCH,
				text: label,
			});

			if (response.ok && response.data) {
				return response.data
					.map((postCode) => ({ label: postCode.code, value: postCode.code }))
					.sort((accumulated, current) => accumulated.label.localeCompare(current.label));
			}
		}

		return [];
	};

export const handlePostalCodeChange =
	(dispatch: Dispatch<any>) =>
	(postCode?: string, countryCode?: string): void => {
		if (postCode && countryCode) {
			dispatch(
				provinceGetByCountryAndPostCode({
					countryCode,
					creationMethod: CreationMethod.BRANCH,
					postCode,
					loader: { active: false },
				}),
			);
			dispatch(
				cityGetByCountryAndPostCode({
					countryCode,
					creationMethod: CreationMethod.BRANCH,
					postCode,
					loader: { active: false },
				}),
			);
		} else {
			dispatch(cityReset());
			dispatch(provinceReset());
		}
	};
