import { useState } from 'react';

import type { ISupplierModifyParams } from '@crac/core/modules/supplier/services/SupplierModifyService';
import { supplierModify } from '@crac/core/modules/supplier/state/actions/SupplierActions';

import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';
import { useManageRequestTemp } from '~/features/shared/hooks/useManageRequest';
import { useSupplierSelector } from '~/features/shared/state/selectors/SupplierSelectors';

import { useSupplierFormCancel } from './useSupplierFormCancel';

const actionsImported = { supplierModify };

interface IUseSupplierModifyParams {
	cancelRoute?: string;
	code?: string;
	resetAddress?: boolean;
}

/**
 * Hook to modify a supplier
 * @param IUseSupplierModifyParams
 * @returns { handleModify }
 */
export const useSupplierModify = ({ code, cancelRoute, resetAddress }: IUseSupplierModifyParams) => {
	const [isSubmitted, setIsSubmitted] = useState(false);

	const { handleCancel } = useSupplierFormCancel({ cancelRoute, resetAddress });

	const { supplierModify } = useBindAndMemoizeActions(actionsImported);
	const { supplierModifyRequest } = useSupplierSelector();
	const { showConfirm } = useAlertMessage();

	const handleModify = async (values: ISupplierModifyParams) => {
		if (!code) {
			return;
		}

		const confirm = await showConfirm({
			message: 'Are you sure you want to modify this supplier?',
			confirmButtonText: 'Yes, modify it',
		});

		if (confirm) {
			supplierModify({ ...values, code });
			setIsSubmitted(true);
		}
	};

	useManageRequestTemp({
		clearCondition: () => setIsSubmitted(false),
		condition: isSubmitted,
		request: supplierModifyRequest,
		successMsg: 'Supplier modified successfully',
		successCallback: handleCancel,
	});

	return { handleModify };
};
