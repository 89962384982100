import { SupplierEndPoints } from './SupplierEndPoints';
import { Api } from '../../shared/api';
import type { IServiceBaseParams } from '../../shared/types/ServiceBaseParams';
import type { ISupplier } from '../entities/Supplier';
import type { SupplierType } from '../types/SupplierType';

export interface ISupplierSearchParams extends IServiceBaseParams {
	provider: string;
	supplier?: string;
	nif?: string;
	type?: SupplierType;
}

/**
 * Search suppliers
 * @param `ISupplierSearchParams`
 * @returns {Supplier[]} `ISupplier[]`
 */
export const supplierSearchService = (model: ISupplierSearchParams) =>
	Api.get<ISupplier[], ISupplierSearchParams>(SupplierEndPoints.SEARCH, model);
