import { BrandParkingEndpoints } from './BrandParkingEndpoints';
import type { ShowActiveType } from '../../../../models/types/ShowActiveType';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBrandParking } from '../entities/BrandParking';

export interface IBrandParkingSearchParams extends IServiceBaseParams {
	id?: number;
	brand?: string;
	country?: string;
	name?: string;
	roadName?: string;
	showActive: ShowActiveType;
	supplier?: string;
}

export const brandParkingSearchService = (model: IBrandParkingSearchParams) => {
	return Api.get<IBrandParking[], IBrandParkingSearchParams>(BrandParkingEndpoints.SEARCH, model);
};
