import React from 'react';

import type { ControllerRenderProps, FieldValues, RegisterOptions } from 'react-hook-form';
import { useController } from 'react-hook-form';
import type { MultiValue, SingleValue } from 'react-select';

import type { ISelectOption } from './types';

export type SelectOptionType<T> = MultiValue<ISelectOption<T>> | SingleValue<ISelectOption<T>>;

interface ISelectControllerProps<T> {
	/** The name of the field, used for form registration and state management */
	name: string;
	/** Optional validators for field validation */
	registerOptions?: RegisterOptions<FieldValues, string>;
	/** Optional flag to disable the select field */
	disabled?: boolean;
	/** Optional callback function to be called on value change */
	onChange?: (value: T) => void;
	/** Optional callback function to be called on blur */
	onBlur?: RegisterOptions['onBlur'];
	/** Function that returns the children components with the necessary props */
	children: (field: ControllerRenderProps<FieldValues, string>) => React.ReactElement;
}

/**
 * SelectController
 *
 * A common wrapper component for handling select and async select fields with react-hook-form.
 * This component manages the state and validation of a select field, and passes the necessary
 * props and handlers to its children.
 *
 * @template T - The type of the select field value
 *
 * @param {ISelectControllerProps<T>} props - The properties for the SelectController component
 * @param {string} props.name - The name of the field, used for form registration and state management
 * @param {RegisterOptions<FieldValues, string>} [props.registerOptions] - Optional validators for field validation
 * @param {function} [props.onChange] - Optional callback function to be called on value change
 * @param {function} [props.onBlur] - Optional callback function to be called on blur
 * @param {function} props.children - Function that returns the children components with the necessary props
 *
 * @returns {JSX.Element} The rendered SelectController component
 */
export const SelectController = <T,>({
	name,
	registerOptions,
	disabled,
	onChange,
	onBlur,
	children,
}: ISelectControllerProps<T>): JSX.Element => {
	/**
	 * Register select field with react-hook-form
	 */
	const { field } = useController({
		name,
		rules: { ...registerOptions, onBlur },
	});

	/**
	 * Handles the change event of the select
	 * @param selectedOption - The selected option(s) of the select field
	 */
	const handleOnChange = (selectedOption: MultiValue<ISelectOption<T>> | SingleValue<ISelectOption<T>>) => {
		let value = null;
		if (Array.isArray(selectedOption)) {
			// Multiple select
			const options = selectedOption as ISelectOption<T>[];
			value = options?.map((item) => item.value);
			if (value.length === 0) {
				value = null;
			}
		} else {
			// Single select
			const option = selectedOption as ISelectOption<T>;
			value = option?.value ?? null;
		}
		// Set the value to the form
		field.onChange(value);

		// Call custom onChange
		onChange?.(value as T);
	};

	return (
		<>
			{children({ ...field, onChange: handleOnChange })}
			{disabled ? <input name={field.name} type="hidden" value={field.value ?? ''} /> : null}
		</>
	);
};
