import {
	budgetServiceAddFiles,
	budgetServiceChangeWorkType,
	budgetServiceDeleteFile,
	budgetServiceGetByPlateNumber,
	budgetServiceGetByVehicleAndId,
	budgetServiceGetByVehicleLine,
	budgetServiceGetFiles,
	budgetServiceImport,
	budgetServiceOpenFile,
	budgetServiceReject,
} from '../../data/services/BudgetService';
import type { IBudget, IBudgetFile } from '../../models/entities/Budget';
import type {
	IBudgetAddFilesParams,
	IBudgetChangeWorkTypeParams,
	IBudgetDeleteFileParams,
	IBudgetGetByPlateNumberParams,
	IBudgetGetByVehicleAndIdParams,
	IBudgetGetByVehicleLineParams,
	IBudgetGetFilesParams,
	IBudgetImportParams,
	IBudgetOpenFileParams,
	IBudgetRejectParams,
} from '../../models/serviceParams/BudgetParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Gets a budget by plate number.
 * @param {IBudget[]} payload - The budget to be returned.
 * @param {IBudgetGetByPlateNumberParams} params - Parameters detailing the budget.
 * @returns {Promise<void>} - A promise that resolves to an array of budgets.
 */
export const budgetGetByPlateNumber = createAsyncAction<IBudget[], IBudgetGetByPlateNumberParams>(
	'budget/getByPlateNumber',
	budgetServiceGetByPlateNumber,
);

/**
 * Gets a budget by vehicle and id.
 * @param {IBudget} payload - The budget to be returned.
 * @param {IBudgetGetByVehicleAndIdParams} params - Parameters detailing the budget.
 * @returns {Promise<void>} - A promise that resolves to the budget.
 */
export const budgetGetByVehicleAndId = createAsyncAction<IBudget, IBudgetGetByVehicleAndIdParams>(
	'budget/GetByVehicleAndCode',
	budgetServiceGetByVehicleAndId,
);

/**
 * Clears the budget.
 * @returns {void}
 */
export const budgetClear = createSyncAction('budget/clear');

/**
 * Imports a budget.
 * @param {IBudget} payload - The budget to be imported.
 * @param {IBudgetImportParams} params - Parameters detailing the budget.
 * @returns {Promise<void>} - A promise that resolves after the budget is imported.
 */
export const budgetImport = createAsyncAction<IBudget, IBudgetImportParams>('budget/import', budgetServiceImport);

export const budgetGetByVehicleLine = createAsyncAction<IBudget[], IBudgetGetByVehicleLineParams>(
	'budget/getByVehicleLine',
	budgetServiceGetByVehicleLine,
);

/**
 * Change budget workType
 * @param `budgetId` number
 * @returns `Promise<IBudget>` returns the provided budget
 */
export const budgetChangeWorkType = createAsyncAction<IBudget, IBudgetChangeWorkTypeParams>(
	'budget/changeWorkType',
	budgetServiceChangeWorkType,
);

/**
 * Reject budget
 * @param {number}`budgetId` number
 * @returns <Promise<IBudget> returns the provided budget rejected
 */
export const budgetReject = createAsyncAction<IBudget, IBudgetRejectParams>('budget/reject', budgetServiceReject);

/**
 * Insert files in budget
 * @payload `IBudgetFile[]` array of inserted files
 * @params `IBudgetAddFilesParams` parameters detailing the files
 * @returns `Promise<void>` promise that returns de inserted files
 */
export const budgetAddFiles = createAsyncAction<IBudgetFile[], IBudgetAddFilesParams>(
	'budget/addFiles',
	budgetServiceAddFiles,
);

/**
 * Get files from budget
 * @payload `IBudgetFile[]` files of a budget
 * @params `IBudgetGetFilesParams` parameters detailing the budget
 * @returns `Promise<void>` promise that returns the files of the budget
 */
export const budgetGetFiles = createAsyncAction<IBudgetFile[], IBudgetGetFilesParams>(
	'budget/getFiles',
	budgetServiceGetFiles,
);

/**
 * Open file by id
 * @payload `boolean` if is open or not
 * @params `IBudgetOpenFileParams` parameters detailing the budgetFile
 * @returns `Promise<void>` promise that returns a boolean
 */
export const budgetOpenFile = createAsyncAction<boolean, IBudgetOpenFileParams>(
	'budget/openFile',
	budgetServiceOpenFile,
);

/**
 * Delete file from budget by id
 * @payload `IBudgetFile` the deleted file
 * @params `IBudgetDeleteFileParams` parameters detailing the budget to remove
 * @returns `Promise<void>` promise that returns the deleted file
 */
export const budgetDeleteFile = createAsyncAction<IBudgetFile, IBudgetDeleteFileParams>(
	'budget/deleteFile',
	budgetServiceDeleteFile,
);
