import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderConfirmDeliveryParams extends IServiceBaseParams {
	id: number;
}

/**
 * Confirms delivery
 * @param params - Parameters to confirm delivery.
 * @returns A promise that resolves a sale order.
 */
export const saleOrderConfirmDeliveryService = (params: ISaleOrderConfirmDeliveryParams) => {
	return Api.post<ISaleOrder, ISaleOrderConfirmDeliveryParams>(SaleOrderEndPoints.CONFIRM_DELIVERY, params);
};
