export enum SupplierEndPoints {
	/**
	 * Supplier search
	 * @host `/supplier/search/`
	 */
	SEARCH = '/supplier/search/',

	/**
	 * Supplier insert
	 * @host `/supplier/insert/`
	 */
	INSERT = '/supplier/insert/',

	/**
	 * Supplier modify
	 * @host `/supplier/modify/`
	 */
	MODIFY = '/supplier/modify/',

	/**
	 * Supplier search by code
	 * @host `/supplier/getByCode/`
	 */
	GET_BY_CODE = '/supplier/getByCode/',

	/**
	 * Supplier get workshop by vehicle
	 * @host `/supplier/GetWorkshopByVehicle/`
	 */
	GET_ALL = '/supplier/GetWorkshopByVehicle/',
}
