import type { ValidationRule } from 'react-hook-form';

import type { IInputValidationRules } from './inputField/types';
import type { IValidationRule } from './types';

export const hasValue = (value: any): boolean => {
	if (value === undefined || value === null || value === '') {
		return false;
	}
	return true;
};

/**
 * Deep compare two objects
 * @param obj1 - The first object
 * @param obj2 - The second object
 * @returns True if the objects are deeply equal, false otherwise
 */
export const deepEqual = (obj1: any, obj2: any): boolean => {
	if ((obj1 ?? null) === (obj2 ?? null)) {
		// Same instance or both are null
		return true;
	}

	if (typeof obj1 !== typeof obj2 || obj1 === null || obj2 === null) {
		// Different types
		return false;
	}

	if (typeof obj1 === 'function') {
		// Compare functions
		return obj1.toString() === obj2.toString();
	}

	if (typeof obj1 !== 'object') {
		// They are not objects
		return false;
	}

	if (obj1 instanceof Date && obj2 instanceof Date) {
		const millis1 = new Date(obj1).setSeconds(0, 0);
		const millis2 = new Date(obj2).setSeconds(0, 0);
		// Handle invalid dates
		if (isNaN(millis1) && isNaN(millis2)) {
			return true;
		}
		return millis1 === millis2;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) {
		// Different number of properties
		return false;
	}

	for (const key of keys1) {
		if (!keys2.includes(key)) {
			// Key is missing in the other object
			return false;
		}
		// Compare the values
		else if (!deepEqual(obj1[key], obj2[key])) {
			return false;
		}
	}

	return true;
};

export const setValidationValue = <T>(
	validation: IInputValidationRules<T>,
	key: keyof IInputValidationRules<T>,
	value: T,
) => {
	if (validation === undefined || validation === null) {
		return;
	}
	if (validation[key] === undefined || validation[key] === null) {
		(validation as { [key: string]: T })[key] = value;
	} else if (typeof validation[key] === 'object') {
		(validation[key] as { value: T }).value = value;
	}
};

/**
 * Get the validation value from a validation rule
 * @param validation The validation rule
 * @returns The validation value
 */
export const getValidationValue = <T>(validation?: IValidationRule<T>) => {
	if (validation === undefined || validation === null) {
		return undefined;
	}
	if (typeof validation === 'function') {
		return validation as T;
	}
	if (typeof validation === 'object' && 'value' in validation) {
		return validation.value as T;
	}
	return validation as T;
};

/**
 * Set the default validation message for a validation rule
 * @param validations The validation rules
 * @param key The key of the validation rule
 * @param message The default message
 */
export const setDefaultValidationMessage = <T>(
	validations: IInputValidationRules<T>,
	key: keyof IInputValidationRules<T>,
	message: string,
	formatter: (value: any) => string = (value: any) => value.toString(),
) => {
	const validation = validations?.[key];
	if (!hasValue(validation)) {
		return;
	}
	if (typeof validation === 'object' && 'value' in validation) {
		const newMessage = (validation.message ?? message) as string;
		validations[key] = {
			...validation,
			message: newMessage.replace('{0}', formatter(validation.value)),
		} as ValidationRule<any>;
	} else {
		validations[key] = {
			value: validation as T,
			message: message.replace('{0}', formatter(validation)),
		} as ValidationRule<any>;
	}
};
