import { BatchEndPoints } from './BatchEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBatch } from '../entities/Batch';
import type { HoldingDateStartType } from '../types/HoldingDateStartType';
import type { HoldingStrategyType } from '../types/HoldingStrategyType';
import type { PaymentTriggerType } from '../types/PaymentTriggerType';

export interface IBatchSearchParams extends IServiceBaseParams {
	code?: string;
	supplier?: string;
	validFrom?: string | Date;
	validTo?: string | Date;
	withPlateNumber?: boolean;
	paymentTrigger?: PaymentTriggerType;
	paymentDays?: number;
	holdingDateStart?: HoldingDateStartType;
	holdingStartStrategy?: HoldingStrategyType;
	holdingEndStrategy?: HoldingStrategyType;
	returnParkingId?: number;
}

/**
 * Search a batch
 * @return {Promise<BatchType>} `BatchType`
 */
export const batchSearchService = (model: IBatchSearchParams): Promise<ServiceResponse<IBatch[]>> => {
	return Api.get<IBatch[], IBatchSearchParams>(BatchEndPoints.SEARCH, model);
};
