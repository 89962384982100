import { useCallback } from 'react';

import type { SweetAlertOptions } from '@crac/components/external/sweet-alert';
import { gCSwal, gCToast } from '@crac/components/external/sweet-alert';

export interface IAlertMessageParams {
	message: string;
	title?: string;
	type?: 'success' | 'error' | 'warning' | 'info' | 'question';
	toast?: boolean;
	confirmButtonText?: string;
	cancelButtonText?: string;
	cancelButtonColor?: string;
	confirmButtonColor?: string;
	customClass?: SweetAlertOptions['customClass'];
}

type ShowAlert = (params: IAlertMessageParams) => Promise<void>;
type ShowConfirm = (params: IAlertMessageParams) => Promise<boolean>;

type UseAlertMessage = () => {
	showAlert: ShowAlert;
	showConfirm: ShowConfirm;
};

export const useAlertMessage: UseAlertMessage = () => {
	const showAlert = useCallback(async ({ message, title, type, toast = false }: IAlertMessageParams) => {
		const defaultTitle = type === 'success' ? 'Success!' : 'Error!';

		if (toast) {
			await gCToast('', message, type ?? 'error');
		} else {
			await gCSwal(title ?? defaultTitle, message, type ?? 'error');
		}
	}, []);

	const showConfirm = useCallback(
		async ({
			message,
			title,
			type,
			cancelButtonText = 'No, cancel',
			confirmButtonText = 'Yes, delete it',
			customClass,
			cancelButtonColor,
			confirmButtonColor,
		}: IAlertMessageParams) => {
			const { value } = await gCSwal(title ?? 'Are you sure?', message, type ?? 'warning', true, {
				confirmButtonText,
				cancelButtonText,
				customClass,
				cancelButtonColor,
				confirmButtonColor,
			});

			return value;
		},
		[],
	);

	return { showAlert, showConfirm };
};
