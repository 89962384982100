import { sleep } from './core/taxValidatorsHelpers';
import { validatorsServiceCheckVAT } from '../../data/services/ValidatorsService';
import type { ICountry } from '../../models/entities/Country';
import type { ICheckVat } from '../../models/entities/Validators';
import type { MessageType } from '../../models/types/MessageType';

let checkVATTimeout = 0;

/**
 * Validate that the input value is a valid VAT number.
 * @param {string} countryCode Code of the country of the VAT number.
 * @param {ICountry[]} countries Countries array.
 * @param {string} errorMessage Error message when the VAT number is not valid.
 */
export const isVAT =
	(countryCode: string, countries: ICountry[], errorMessage = 'Invalid tax identification number') =>
	async (value: string | undefined): Promise<string | undefined> => {
		const currentCountry = countries.find((item) => item.code === countryCode);
		if (!countries || (currentCountry && !currentCountry.validateCompanyVAT)) {
			return undefined;
		}
		if (value === undefined || value === null || value === '') {
			return undefined;
		}

		const timestamp = Date.now();
		checkVATTimeout = timestamp;
		await sleep(1000);
		if (timestamp !== checkVATTimeout) {
			return undefined;
		}

		const response = await validatorsServiceCheckVAT({ countryCode, vatNumber: value });

		// eslint-disable-next-line init-declarations
		let data: ICheckVat | undefined;
		let ok = true;
		let errors: MessageType[] = [];

		if (response.ok && response.data) {
			data = { ...response.data };
		} else {
			errors = [...response.errors];
			ok = false;
		}

		if (ok && data && data.isValid) {
			return undefined;
		}
		/*
		 * ERROR CODE 0 EXCEPTION
		 * ERROR CODE 1 COUNTRY NOT VALID
		 */
		if (!ok && errors.filter((msg) => parseInt(msg.code as string, 10) < 2).length) {
			return undefined;
		}
		// NO ERRORS TO DISPLAY
		if ((!ok && !errors.filter((msg) => parseInt(msg.code as string, 10) >= 2).length) || (data && !data.isValid)) {
			return errorMessage;
		}
		// DISPLAY FIRST ERROR
		return errors[0].message;
	};
