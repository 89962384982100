import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const SaleOrder = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrder'));

const SaleOrderNew = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderNew'));

const SaleOrderNewCustomer = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderNewCustomer'));

const SaleOrderNewConfirmation = lazyWithRetry(
	() => import('~/features/sale/saleOrder/pages/SaleOrderNewConfirmation'),
);

const SaleOrderConfirmPrice = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderConfirmPrice'));

const SaleOrderModify = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderModify'));

const SaleOrderSetBlockToSale = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderSetBlockToSale'));

const SaleOrderChangeAmounts = lazyWithRetry(() => import('~/features/sale/saleOrder/pages/SaleOrderChangeAmounts'));

const SaleOrderStartReconditioning = lazyWithRetry(
	() => import('~/features/sale/saleOrder/pages/SaleOrderStartReconditioning'),
);

export const saleOrderRoutes: { [key: number]: IRouteConfig } = {};

// SEARCH
saleOrderRoutes[routesIdMap.SaleOrder] = {
	component: SaleOrder,
	exact: true,
	id: routesIdMap.SaleOrder,
	name: 'Sale orders',
	menuText: 'Orders',
	path: '/sale/orders',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'shopping-bag',
};

// NEW
saleOrderRoutes[routesIdMap.SaleOrderNew] = {
	component: SaleOrderNew,
	exact: true,
	id: routesIdMap.SaleOrderNew,
	name: 'New sale order',
	menuText: 'New sale order',
	path: '/sale/orders/new',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'plus',
};

// B2B
saleOrderRoutes[routesIdMap.SaleOrderNewCompany] = {
	component: SaleOrderNewCustomer,
	exact: true,
	id: routesIdMap.SaleOrderNewCompany,
	name: 'New sale order',
	path: '/sale/orders/new/company/:code?',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2B],
	childrens: [],
	icon: 'plus',
};

saleOrderRoutes[routesIdMap.SaleOrderNewCompanyConfirmation] = {
	component: SaleOrderNewConfirmation,
	exact: true,
	id: routesIdMap.SaleOrderNewCompanyConfirmation,
	name: 'New sale order',
	path: '/sale/orders/new/confirmation/:code',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2B],
	childrens: [],
	icon: 'plus',
};

// B2C
saleOrderRoutes[routesIdMap.SaleOrderNewCustomer] = {
	component: SaleOrderNewCustomer,
	exact: true,
	id: routesIdMap.SaleOrderNewCustomer,
	name: 'New sale order',
	path: '/sale/orders/new/customer/:plateNumber',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2C],
	childrens: [],
	icon: 'plus',
};

saleOrderRoutes[routesIdMap.SaleOrderNewCustomerCheck] = {
	component: SaleOrderNewCustomer,
	exact: true,
	id: routesIdMap.SaleOrderNewCustomerCheck,
	name: 'New sale order',
	path: '/sale/orders/new/customer-check/:plateNumber/:customerCode',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2C],
	childrens: [],
	icon: 'plus',
};

saleOrderRoutes[routesIdMap.SaleOrderNewCustomerConfirmation] = {
	component: SaleOrderNewConfirmation,
	exact: true,
	id: routesIdMap.SaleOrderNewCustomerConfirmation,
	name: 'New sale order',
	path: '/sale/orders/new/confirmation/:plateNumber/:customerCode',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderInsertB2C],
	childrens: [],
	icon: 'plus',
};

// MODIFY
saleOrderRoutes[routesIdMap.SaleOrderModify] = {
	component: SaleOrderModify,
	exact: true,
	id: routesIdMap.SaleOrderModify,
	name: 'Modify sale orders',
	path: '/sale/orders/:id',
	permissions: [PermissionsType.SaleOrder],
	childrens: [
		routesIdMap.SaleOrderSetBlockToSale,
		routesIdMap.SaleOrderChangeAmounts,
		routesIdMap.SaleOrderConfirmPrice,
	],
	icon: 'pencil',
};

saleOrderRoutes[routesIdMap.SaleOrderSetBlockToSale] = {
	component: SaleOrderSetBlockToSale,
	exact: true,
	id: routesIdMap.SaleOrderSetBlockToSale,
	name: 'Block to sale',
	path: '/sale/orders/:id/block-to-sale',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderSetBlockToSale],
	childrens: [],
	icon: 'pencil',
	isChild: true,
};

saleOrderRoutes[routesIdMap.SaleOrderChangeAmounts] = {
	component: SaleOrderChangeAmounts,
	exact: true,
	id: routesIdMap.SaleOrderChangeAmounts,
	name: 'Block to sale',
	path: '/sale/orders/:id/change-amounts',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderChangeAmounts],
	childrens: [],
	icon: 'pencil',
	isChild: true,
};

saleOrderRoutes[routesIdMap.SaleOrderStartReconditioning] = {
	component: SaleOrderStartReconditioning,
	exact: true,
	id: routesIdMap.SaleOrderStartReconditioning,
	name: 'Start reconditioning',
	path: '/sale/orders/:id/start-reconditioning',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderStartReconditioning],
	childrens: [],
	icon: 'pencil',
};

saleOrderRoutes[routesIdMap.SaleOrderConfirmPrice] = {
	component: SaleOrderConfirmPrice,
	exact: true,
	id: routesIdMap.SaleOrderConfirmPrice,
	name: 'Confirm price',
	path: '/sale/orders/:id/confirm-price',
	permissions: [PermissionsType.SaleOrder, PermissionsType.SaleOrderConfirmPrice],
	childrens: [],
	icon: 'pencil',
	isChild: true,
};
