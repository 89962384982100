import React from 'react';

import classNames from 'classnames';
import type { ControllerProps } from 'react-hook-form';
import { useController, useFormContext } from 'react-hook-form';

import { formatDateByType } from './helpers';
import { useInputFieldDateProps } from './hooks';
import type { IInputDateProps } from './types';

export const InputFieldDate: React.FC<IInputDateProps<Date | null>> = ({ component: Component, innerRef, ...rest }) => {
	/* Hooks */
	const { onChange, onBlur, validation, children, ...props } = useInputFieldDateProps(rest);
	const { name } = props;

	const { control, formState, getFieldState } = useFormContext();

	const { field } = useController({
		name,
		control,
		rules: validation as ControllerProps['rules'],
	});

	/* Handlers */
	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement> | string | Date) => {
		let valueAsDate = null;
		if (event && typeof event === 'object' && 'target' in event) {
			const { value } = event.target;
			valueAsDate = value ? new Date(value) : null;
		} else if (event instanceof Date) {
			valueAsDate = event as Date;
		} else if (typeof event === 'string') {
			valueAsDate = new Date(event);
		}
		field.onChange(valueAsDate);
		if (onChange) {
			onChange(valueAsDate);
		}
	};

	const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		field.onBlur();
		if (onBlur) {
			onBlur(event);
		}
	};

	/* Constants */
	const { error } = getFieldState(name, formState);
	const componentClassName = classNames('form-control', {
		'is-invalid': Boolean(error),
	});

	const handleRef = (ref: HTMLInputElement | null) => {
		field.ref(ref);
		if (innerRef) {
			innerRef.current = ref;
		}
	};

	if (Component) {
		// Ensure that the date is in the correct format
		const value = typeof field.value === 'string' ? new Date(field.value) : field.value;
		return (
			<Component
				{...props}
				className={componentClassName}
				max={props.max ? new Date(props.max) : undefined}
				min={props.min ? new Date(props.min) : undefined}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
				ref={handleRef}
				value={value ?? null}
			>
				{typeof children === 'function' ? children(field) : children}
			</Component>
		);
	}

	// Ensure that the date is in the correct format
	const value = formatDateByType(field.value, props.type) || '';

	return (
		<input
			{...props}
			className={componentClassName}
			defaultValue={value}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			ref={handleRef}
		/>
	);
};
