import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IBatch } from '../../entities/Batch';
import type { IBatchDeleteParams } from '../../services/BatchDeleteService';
import { batchDeleteService } from '../../services/BatchDeleteService';
import type { IBatchInsertParams } from '../../services/BatchInsertService';
import { batchInsertService } from '../../services/BatchInsertService';
import type { IBatchModifyParams } from '../../services/BatchModifyService';
import { batchModifyService } from '../../services/BatchModifyService';
import type { IBatchSearchParams } from '../../services/BatchSearchService';
import { batchSearchService } from '../../services/BatchSearchService';

/**
 * Clears the batch data from the state.
 */
export const batchClear = createSyncAction('fleet/batchClear');

/**
 * Searches for batches based on search parameters.
 * @param {IBatch[]} payload `IBatch[]` The payload for the action.
 * @param {IBatchSearchParams} IBatchSearchParams The parameters for retrieving batches.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of batches.
 */
export const batchSearch = createAsyncAction<IBatch[], IBatchSearchParams>('fleet/batch/search', batchSearchService);

/**
 * Inserts a new batch into the fleet.
 * @param {IBatch} payload `IBatch` The batch to be inserted.
 * @param {IBatchInsertParams} IBatchInsertParams The parameters for inserting a new batch.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the batch has been inserted.
 */
export const batchInsert = createAsyncAction<IBatch, IBatchInsertParams>('fleet/batch/insert', batchInsertService);

/**
 * Modifies an existing batch in the fleet.
 * @param {IBatch} payload `IBatch` The batch to be modified.
 * @param {IBatchModifyParams} IBatchModifyParams The parameters for modifying an existing batch.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the batch has been modified.
 */
export const batchModify = createAsyncAction<IBatch, IBatchModifyParams>('fleet/batch/modify', batchModifyService);

/**
 * Deletes a batch from the fleet.
 * @param {string} payload `string` The code of the batch to be deleted.
 * @param {IBatchDeleteParams} IBatchDeleteParams The parameters for deleting a batch.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the batch has been deleted.
 */
export const batchDelete = createAsyncAction<IBatch, IBatchDeleteParams>('fleet/batch/delete', batchDeleteService);
