import { useFormContext } from 'react-hook-form';

import { computeComponentClassName } from './helpers';
import { selectStyles } from './styles';
import type { ISelectFieldProps } from './types';

/**
 * Hook to get the props for an select field
 * @param param0 - The properties for the select field
 * @returns - The props for the select field
 */
export const useSelectFieldProps = <T>({ validation, ...rest }: ISelectFieldProps<T>) => {
	const { id, name } = rest;

	const { formState, getFieldState } = useFormContext();
	const { error } = getFieldState(name, formState);

	let { placeholder } = rest;
	if (!placeholder) {
		// Default placeholder
		placeholder = rest.loadOptions ? 'Start typing to search' : 'Select one option';
	}

	const props = {
		...rest,
		id: undefined,
		// Ensure id is set
		inputId: id ?? name,
		placeholder,
		isSearchable: true,
		isDisabled: rest.isLoading || rest.disabled,
		isMulti: rest.canSelectAll || rest.multiple,
		className: 'form-control p-0 border-0',
	};

	if (!validation) {
		// Return props without validation
		return {
			...props,
			styles: selectStyles,
		};
	}

	// Return props
	return {
		...props,
		...computeComponentClassName({
			id: props.inputId,
			error,
			defaultClassName: 'form-control p-0 border-0',
			defaultStyles: selectStyles,
		}),
	};
};
