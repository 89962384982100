import { SupplierEndPoints } from './SupplierEndPoints';
import type { ISupplierInsertParams } from './SupplierInsertService';
import { Api } from '../../shared/api';
import type { ISupplier } from '../entities/Supplier';

export interface ISupplierModifyParams extends ISupplierInsertParams {
	code: string;
}

/**
 * Modify existing supplier.
 * @param {ISupplierModifyParams} model - The supplier data to modify.
 * @returns {Promise<ISupplier>} - A promise that resolves to the modified supplier.
 */
export const supplierModifyService = (model: ISupplierModifyParams) =>
	Api.post<ISupplier, ISupplierModifyParams>(SupplierEndPoints.MODIFY, model);
