import { BatchEndPoints } from './BatchEndPoints';
import type { IBatchInsertParams } from './BatchInsertService';
import { Api } from '../../../shared/api';
import type { ServiceResponse } from '../../../shared/types/ServiceResponse';
import type { IBatch } from '../entities/Batch';

export interface IBatchModifyParams extends Omit<IBatchInsertParams, 'lines'> {
	code: string;
}

/**
 * Modify a batch
 * @return {Promise<BatchType>} `BatchType`
 */
export const batchModifyService = (model: IBatchModifyParams): Promise<ServiceResponse<IBatch>> => {
	return Api.post<IBatch, IBatchModifyParams>(BatchEndPoints.MODIFY, model);
};
