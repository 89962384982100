import React, { useMemo } from 'react';

import { type ControllerRenderProps, type FieldValues, useFormContext } from 'react-hook-form';

import { SelectAllLink } from './styles';
import { useField } from '../hooks';
import { InputField } from '../inputField';

interface ISelectNoneController {
	children: (field: { disabled: boolean }) => React.ReactElement;
	field: ControllerRenderProps<FieldValues, string>;
	disabled?: boolean;
	canSelectNone?: boolean;
	valueType?: 'number' | 'string';
}

export const SelectNoneController = ({
	children,
	field,
	canSelectNone,
	disabled,
	valueType,
}: ISelectNoneController) => {
	const checkboxName = useMemo(() => `${field.name}-select-none`, [field.name]);
	const isDisabled = useField(checkboxName);
	const { setValue } = useFormContext();

	if (!canSelectNone) {
		return children({ disabled: false });
	}

	const handleOnChange = (value: boolean) => {
		if (!disabled) {
			if (value) {
				if (valueType === 'number') {
					field.onChange([{ value: 0 }]);
				} else {
					field.onChange([{ value: 'none' }]);
				}
			} else {
				field.onChange(null);
				setValue(checkboxName, null, { shouldDirty: true, shouldValidate: true });
			}
		}
	};

	return (
		<div className="position-relative">
			<SelectAllLink className="position-absolute" style={{ right: '5px', top: '-23px', userSelect: 'none' }}>
				<small>
					<InputField
						label="Find empty"
						name={checkboxName}
						onChange={(value) => handleOnChange(value as boolean)}
						type="checkbox"
					/>
				</small>
			</SelectAllLink>
			{children({ disabled: isDisabled })}
		</div>
	);
};
