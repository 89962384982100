import type { ISupplier } from '@crac/core/modules/supplier/entities/Supplier';
import type { ISupplierInsertParams } from '@crac/core/modules/supplier/services/SupplierInsertService';

interface ISupplierFormInitialValue {
	initialValues: Partial<ISupplierInsertParams> | undefined;
}

export const useSupplierFormInitialValue = (supplier?: ISupplier): ISupplierFormInitialValue => {
	if (!supplier) {
		return {
			initialValues: undefined,
		};
	}

	return {
		initialValues: {
			...supplier,
		},
	};
};
