import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderConfirmDepositReceivedParams extends IServiceBaseParams {
	id: number;
}

/**
 * Confirms deposit is received
 * @param params - Parameters to confirm deposit is received.
 * @returns A promise that resolves a sale order.
 */
export const saleOrderConfirmDepositReceivedService = (params: ISaleOrderConfirmDepositReceivedParams) => {
	return Api.post<ISaleOrder, ISaleOrderConfirmDepositReceivedParams>(
		SaleOrderEndPoints.CONFIRM_DEPOSIT_RECEIVED,
		params,
	);
};
