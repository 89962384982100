const emailValidationExpression =
	// eslint-disable-next-line prefer-named-capture-group
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/u;

/**
 * Check if the input value is a valid email.
 * @param errorMessage Error message when the input value is not a valid email.
 */
export const isEmail =
	(errorMessage = 'Invalid email') =>
	<T>(value?: T): string | undefined =>
		value === null ||
		value === '' ||
		value === undefined ||
		(typeof value === 'string' && emailValidationExpression.test(value))
			? undefined
			: errorMessage;
