import type { FC } from 'react';
import { useContext } from 'react';

import { Navigate, useParams } from 'react-router-dom';

import { SupplierType } from '@crac/core/modules/supplier/types/SupplierType';

import { routesIdMap } from '~/config/routesIdMap';
import { AppContext } from '~/context/AppContext';
import { routes } from '~/features/routes';
import { SupplierForm } from '~/features/shared/components/supplier/form';
import { useCheckRoutePermission } from '~/features/shared/hooks/useCheckRoutePermission';
import { useSupplierSelector } from '~/features/shared/state/selectors/SupplierSelectors';

const SaleSupplierModify: FC = () => {
	//* Contexts
	const { permissions } = useContext(AppContext);

	const currentRoute = routes[routesIdMap.SaleSupplierModify];
	const cancelRoute = routes[routesIdMap.SaleSupplier];

	//* Hooks
	const { suppliers } = useSupplierSelector();
	const { hasPermission } = useCheckRoutePermission(currentRoute, permissions);
	const { id } = useParams();

	if (!hasPermission || !id) {
		return <Navigate to={routes[routesIdMap.Dashboard].path} />;
	}

	const supplier = suppliers.find(({ code }) => code === id);

	if (!supplier) {
		return null;
	}

	return <SupplierForm cancelRoute={cancelRoute.path} isModal supplier={supplier} type={SupplierType.SELLER} />;
};

export default SaleSupplierModify;
