import type { HttpMethodType } from '../types/HttpMethodsType';
import type { HttpStatusCodeType } from '../types/HttpStatusCodeType';
import type { IServiceBaseParams } from '../types/ServiceBaseParams';

/**
 * Return token from local storage if exists
 * @returns {string} token
 */
export const getToken = (): string | null => {
	const token = typeof localStorage === 'undefined' ? null : localStorage.getItem('token');
	if (token) {
		return JSON.parse(token);
	}

	return null;
};

/**
 * Genera te item form data to request
 * @param formData form data object
 * @param name Property name
 * @param data Data to request
 */
export const getItemFormData = (formData: any, name: string, data: any): void => {
	if (data === null || data === undefined || data === '' || name === 'token') {
		return;
	}

	switch (typeof data) {
		case 'object':
			switch (data.constructor.name) {
				case 'Object':
					if (data.blobId) {
						formData.append(name, data);
					} else {
						Object.keys(data).forEach((key) => {
							getItemFormData(formData, `${name}.${key}`, data[key]);
						});
					}
					break;
				case 'FileList':
					for (let item = 0; item < data.length; item += 1) {
						formData.append(`${name}[${item}]`, data.item(item));
					}
					break;
				case 'Date':
					formData.append(name, data.toJSON());
					break;
				case 'Array':
					if (data.length > 0 && ['File', 'Blob'].includes(data[0].constructor.name)) {
						for (let index = 0; index < data.length; index += 1) {
							getItemFormData(formData, `${name}`, data[index]);
						}
					} else {
						for (let index = 0; index < data.length; index += 1) {
							getItemFormData(formData, `${name}[${index}]`, data[index]);
						}
					}
					break;
				case 'String':
					formData.append(name, data.trim());
					break;
				default:
					formData.append(name, data);
					break;
			}
			break;
		case 'string':
			formData.append(name, data.trim());
			break;
		default:
			formData.append(name, data);
			break;
	}
};

/**
 * Generate querystring from form data
 * @param formData Form data
 */
export const getQueryString = (formData: any): string => {
	const queryStringArray: Array<any> = [];
	if (formData) {
		// USE IN SERVER SIDE
		if (formData.constructor.name === 'URLSearchParams') {
			if (formData.has('token')) {
				formData.delete('token');
			}

			const query = (formData as URLSearchParams).toString();

			return query ? `?${query}` : '';
			// REACT NATIVE
		} else if (formData?.getParts) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			formData?.getParts().forEach((value: any, key: any) => {
				queryStringArray.push(`${value.fieldName}=${value.string}`);
			});

			// CLIENT SIDE
		} else {
			formData.forEach((value: any, key: any) => {
				queryStringArray.push(`${key}=${value}`);
			});
		}

		if (queryStringArray.length > 0) {
			return `?${queryStringArray.join('&')}`;
		}
	}

	return '';
};

/**
 * Convert object data to queryString
 * @param data dato to convert
 * @returns {FormData} form data
 */
export const getFormData = (data: any): FormData | URLSearchParams => {
	const formData = typeof FormData === 'undefined' ? new URLSearchParams() : new FormData();

	if (!data) {
		return formData;
	}

	Object.keys(data).forEach((key) => {
		getItemFormData(formData, key, data[key]);
	});

	return formData;
};

const normalizeLanguageCode = (locale = 'en-GB'): string => {
	if (locale) {
		switch (locale) {
			case 'es':
				return 'es-ES';
			case 'fr':
				return 'fr-FR';
			case 'de':
				return 'de-DE';
			case 'nl':
				return 'nl-NL';
			case 'ru':
				return 'ru-RU';
			case 'it':
				return 'it-IT';
			case 'sv':
				return 'sv-SE';
			case 'nb':
				return 'nb-NO';
			case 'pt':
				return 'pt-PT';
			case 'ca':
				return 'ca-ES';
			case 'el':
				return 'el-GR';
			default:
				return 'en-GB';
		}
	}

	return locale;
};

/**
 * Add headers to request
 * @param method HttpMethodType
 * @param data
 * @returns
 */
export const getHeaders = <P extends IServiceBaseParams | undefined>(method: HttpMethodType, data?: P) => {
	const headers = new Headers();
	const token = data && data.token ? data.token : getToken();

	let acceptLanguage: string | undefined = data && data.locale ? data.locale : undefined;
	if (typeof localStorage !== 'undefined' && !acceptLanguage) {
		if (!acceptLanguage && localStorage.getItem('locale')) {
			acceptLanguage = JSON.parse(localStorage.getItem('locale') as string);
		} else if (!acceptLanguage && sessionStorage.getItem('locale')) {
			acceptLanguage = JSON.parse(sessionStorage.getItem('locale') as string);
		}
	}

	if (!acceptLanguage) {
		acceptLanguage = 'en';
	}

	headers.append('Accept', 'application/json');
	headers.append('Content-Language', normalizeLanguageCode(acceptLanguage));

	// add TOKEN only on private requests
	if (token && !data?.isPublic) {
		headers.append('Authorization', `Bearer ${token}`);
	}

	if (data?.appId) {
		headers.append('AppId', data.appId);
	}

	switch (method) {
		case 'GET':
		case 'HEAD':
			headers.append('Content-Type', 'application/x-www-form-urlencoded');
			break;
		default:
			break;
	}

	return headers;
};

export const sendRequestStatusPostMessage = (status: HttpStatusCodeType) => {
	if (typeof window === 'undefined' || window.postMessage === undefined) {
		return;
	}

	window.postMessage(status, '*');
};
