import { VehicleEngineEndPoints } from './VehicleEngineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicleEngine } from '../entities/VehicleEngine';

export interface IVehicleEngineDeleteParams extends IServiceBaseParams {
	id: number;
}

export const vehicleEngineServiceDelete = (params: IVehicleEngineDeleteParams) => {
	return Api.post<IVehicleEngine, IVehicleEngineDeleteParams>(VehicleEngineEndPoints.DELETE, params);
};
