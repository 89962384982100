import { VehicleEngineEndPoints } from './VehicleEngineEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IVehicleEngine } from '../entities/VehicleEngine';

export interface IVehicleEngineInsertParams extends IServiceBaseParams {
	metaModelId: number;
	name: string;
}

export const vehicleEngineServiceInsert = (params: IVehicleEngineInsertParams) => {
	return Api.post<IVehicleEngine, IVehicleEngineInsertParams>(VehicleEngineEndPoints.INSERT, params);
};
