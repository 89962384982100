import { createSlice } from '@reduxjs/toolkit';

import { budgetLineClear, budgetLineGetByBudgetAndVehicle } from '@crac/core/redux/actions/BudgetLineActions';
import type { IBudgetLineReducerState } from '@crac/core/redux-store/reducersState/budgetLine';

const initialState: IBudgetLineReducerState = {
	budgetLineGetByBudgetAndVehicleRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	budgetLines: [],
};

const budgetLinesSlice = createSlice({
	name: 'BudgetLineSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(budgetLineClear, (state) => {
			return {
				...state,
				budgetLines: [],
			};
		});
		// BUDGET LINE GET BY BUDGET AND PLATE NUMBER
		builder
			.addCase(budgetLineGetByBudgetAndVehicle.pending, (state) => {
				return {
					...state,
					budgetLineGetByBudgetAndVehicleRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(budgetLineGetByBudgetAndVehicle.fulfilled, (state, action) => {
				return {
					...state,
					budgetLineGetByBudgetAndVehicleRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					budgetLines: action.payload,
				};
			})
			.addCase(budgetLineGetByBudgetAndVehicle.rejected, (state, action) => {
				return {
					...state,
					budgetLineGetByBudgetAndVehicleRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const budgetLinesReducer = budgetLinesSlice.reducer;
