import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import type { RepairCostType } from '../../../../models/types/RepairCostType';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderAcceptReconditioningParams extends IServiceBaseParams {
	damageRepairCosts: {
		damagePlateNumber: string;
		damageDocumentNumber?: string;
		damageLineNumber: number;
		repairCostType: RepairCostType;
		repairCostId: number;
		repairCostLine: number;
	}[];
}

/**
 * Accepts reconditioning of the sale order.
 * @param params - Parameters for accepting the reconditioning.
 * @returns Promise that resolves to the accepted sale order.
 */
export const saleOrderServiceAcceptReconditioning = (model: ISaleOrderAcceptReconditioningParams) => {
	return Api.post<ISaleOrder, ISaleOrderAcceptReconditioningParams>(SaleOrderEndPoints.ACCEPT_RECONDITIONING, model);
};
