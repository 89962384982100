import { createSyncAction } from '../../../../../modules/shared/state/createAction';
import { createAsyncAction } from '../../../../../modules/shared/state/createAsyncAction';
import type { IVehicleVersion } from '../../entities/VehicleVersion';
import type {
	IVehicleVersionDeleteParams,
	IVehicleVersionInsertParams,
	IVehicleVersionModifyParams,
	IVehicleVersionSearchParams,
} from '../../services/VehicleVersionServices';
import {
	vehicleVersionDeleteService,
	vehicleVersionInsertService,
	vehicleVersionModifyService,
	vehicleVersionSearchService,
} from '../../services/VehicleVersionServices';

/**
 * Search vehicle versions by name
 */
export const vehicleVersionSearch = createAsyncAction<IVehicleVersion[], IVehicleVersionSearchParams>(
	'vehicleVersion/search',
	vehicleVersionSearchService,
);

/**
 * Insert a vehicle version
 */
export const vehicleVersionInsert = createAsyncAction<IVehicleVersion, IVehicleVersionInsertParams>(
	'vehicleVersion/insert',
	vehicleVersionInsertService,
);

/**
 * Modify a vehicle version
 */
export const vehicleVersionModify = createAsyncAction<IVehicleVersion, IVehicleVersionModifyParams>(
	'vehicleVersion/modify',
	vehicleVersionModifyService,
);

/**
 * Delete a vehicle version by id
 */
export const vehicleVersionDelete = createAsyncAction<IVehicleVersion, IVehicleVersionDeleteParams>(
	'vehicleVersion/delete',
	vehicleVersionDeleteService,
);

export const vehicleVersionClear = createSyncAction('vehicleVersion/clear');
