export enum DigitizationEndPoints {
	/**
	 *
	 * @host `/customerdigitization/digitize/`
	 */
	DIGITIZATION_DIGITIZE = '/customerdigitization/digitize/',

	/**
	 *	Remove digitization
	 * @host `/customerdigitization/remove/`
	 */
	DIGITIZATION_REMOVE = '/customerDigitization/removeDigitize/',
	/**
	 *	Search digitization
	 * @host `/customerdigitization/search/`
	 */
	SEARCH = '/customerDigitization/search/',
	/**
	 *	Gets digitization by id
	 * @host `/customerdigitization/getbyid/`
	 */
	GET_BY_ID = '/customerDigitization/getbyid/',
}
