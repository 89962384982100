import { useEffect, useMemo, useState } from 'react';

import { isVAT } from '@crac/core/helpers/formValidators/isVAT';
import { useWatch } from '@crac/form/form/hooks';

import { useGetBranchesSelector } from '~/features/shared/state/selectors/BranchSelectors';
import { useCountrySelector } from '~/features/shared/state/selectors/CountrySelectors';

/**
 * Es específico para el campo NIF de un supplier porque hay se puede validar usando
 * cuntry o company para saber el pais
 */
export const useSupplierFormValidateNif = () => {
	const [countryCode, nif, provider] = useWatch({ name: ['country', 'nif', 'provider'] });
	const [nifValidation, setNifValidation] = useState<(() => string) | undefined>();

	const { branches } = useGetBranchesSelector();
	const { countries } = useCountrySelector();

	/**
	 * El NIF se valida con el pais o con la empresa dependiendo de los inputs que se visualizan
	 * - Si mostramos el bloque de address, se valida con el pais
	 * - Si no mostramos el bloque de address, se valida con la empresa buscando su país en la lista de branches
	 */
	const cuntry = useMemo(() => {
		let selectedCountryCode = null;

		if (countryCode) {
			selectedCountryCode = countryCode;
		}

		// Solo usamos el provider si no hay countryCode
		if (provider && !selectedCountryCode) {
			const branch = branches.find((item) => item.provider === provider);
			if (branch) {
				selectedCountryCode = branch?.country;
			}
		}

		if (selectedCountryCode) {
			const country = countries.find(
				(country) => country.code.toLowerCase() === selectedCountryCode.toLowerCase(),
			);

			if (country?.data) {
				return selectedCountryCode;
			}
		}

		return undefined;
	}, [branches, provider, countries, countryCode]);

	useEffect(() => {
		const validate = async () => {
			if (!cuntry) {
				setNifValidation(undefined);
				return;
			}
			const result = await isVAT(cuntry, countries)(nif);
			setNifValidation(() => (result ? () => result : undefined));
		};
		// Validate when the country code or the nif changes
		validate();
	}, [cuntry, nif, countries]);

	return { nifValidation };
};
