import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderConfirmPriceParams extends IServiceBaseParams {
	id: number;
	isDepositRequired?: boolean;
}

/**
 * Confirms price
 * @param params - Parameters to confirm price.
 * @returns A promise that resolves a sale order.
 */
export const saleOrderConfirmPriceService = (params: ISaleOrderConfirmPriceParams) => {
	return Api.post<ISaleOrder, ISaleOrderConfirmPriceParams>(SaleOrderEndPoints.CONFIRM_PRICE, params);
};
