export enum ValidatorsEndPoints {
	/**
	 * Validators check VAT
	 * @host `/validators/checkvat/`
	 */
	CHECK_VAT = '/validators/checkvat/',

	/**
	 * Validators check TIN
	 * @host `/validators/checktin/`
	 */
	CHECK_TIN = '/validators/checktin/',

	/**
	 * Validate captcha token
	 * @host `/validators/tokenCaptcha/`
	 */
	VALIDATE_CAPTCHA = '/validators/tokenCaptcha/',
}
