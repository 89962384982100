import { SupplierEndPoints } from './SupplierEndPoints';
import { Api } from '../../shared/api';
import type { IServiceBaseParams } from '../../shared/types/ServiceBaseParams';
import type { WorkshopType } from '../../workshop/types/WorkshopType';
import type { ISupplier } from '../entities/Supplier';
import type { SupplierType } from '../types/SupplierType';

export interface ISupplierInsertParams extends IServiceBaseParams {
	address?: string;
	city?: string;
	contact?: string;
	country?: string;
	county?: string;
	email?: string;
	globalDimensionCode2?: string;
	name: string;
	nif: string;
	paymentMethodCode?: string;
	paymentTermCode?: string;
	phone?: string;
	postCode?: string;
	provider: string;
	type: SupplierType;
	workshopType?: WorkshopType;
}

/**
 * Inserts a supplier.
 * @param {ISupplierInsertParams} model - The supplier data to insert.
 * @returns {Promise<ISupplier>} - A promise that resolves to the inserted supplier.
 */
export const supplierInsertService = (model: ISupplierInsertParams) =>
	Api.post<ISupplier, ISupplierInsertParams>(SupplierEndPoints.INSERT, model);
