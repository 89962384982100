import React from 'react';

import { useWatch } from 'react-hook-form';
import type { FieldValues, Path } from 'react-hook-form';

interface IFieldWatcherProps<T extends FieldValues> {
	/** The name of the field or fields to watch */
	name: keyof T | (keyof T)[];
	/** A function that returns the children components with the watched field values */
	children: (result: Partial<T>) => React.ReactNode | void;
}

/**
 * FieldWatcher
 *
 * A component that watches specified form fields changes using react-hook-form's useWatch hook.
 * It passes the current values of the watched fields to its children as a prop.
 *
 * @template T - The type of the field values
 *
 * @param {IFieldWatcherProps<T>} props - The properties for the FieldWatcher component
 * @param {keyof T | (keyof T)[]} props.name - The name of the field or fields to watch
 * @param {function} props.children - A function that returns the children components with the watched field values
 *
 * @returns {JSX.Element} The rendered FieldWatcher component
 */
export const FieldWatcher = <T extends FieldValues>({ name, children }: IFieldWatcherProps<T>): JSX.Element => {
	const value = useWatch<T>({
		name: name as Path<T>,
	});

	const result = (
		Array.isArray(name)
			? name.reduce((acc, key, index) => {
					acc[key] = value[index];
					return acc;
				}, {} as Partial<T>)
			: { [name]: value }
	) as Partial<T>;

	return <>{children(result)}</>;
};
