import { SaleOrderEndPoints } from './SaleOrderEndPoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleOrder } from '../entities/SaleOrder';

export interface ISaleOrderRemoveUnsoldParams extends IServiceBaseParams {
	id: number;
	plateNumbers?: string[];
}

/**
 * Removes vehicles from a sale order
 * @param params - Parameters to remove vehicles from a sale order.
 * @returns A promise that resolves a sale order.
 */
export const saleOrderRemoveUnsoldService = (params: ISaleOrderRemoveUnsoldParams) => {
	return Api.post<ISaleOrder, ISaleOrderRemoveUnsoldParams>(SaleOrderEndPoints.REMOVE_UNSOLD, params);
};
