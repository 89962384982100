import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Batch = lazyWithRetry(() => import('./pages/Batch'));
const BatchNew = lazyWithRetry(() => import('./pages/BatchNew'));
const BatchModify = lazyWithRetry(() => import('./pages/BatchModify'));

export const batchRoutes: { [key: number]: IRouteConfig } = {};

batchRoutes[routesIdMap.FleetBatch] = {
	component: Batch,
	exact: true,
	id: routesIdMap.FleetBatch,
	name: 'Batch',
	path: '/fleet/batch',
	permissions: [PermissionsType.Fine],
	icon: 'car',
	menuText: 'Batch',
};

batchRoutes[routesIdMap.FleetBatchNew] = {
	component: BatchNew,
	exact: true,
	id: routesIdMap.FleetBatchNew,
	name: 'New batch',
	path: '/fleet/batch/new',
	permissions: [PermissionsType.Fine],
	icon: 'plus',
	menuText: 'New batch',
};

batchRoutes[routesIdMap.FleetBatchModify] = {
	component: BatchModify,
	exact: true,
	id: routesIdMap.FleetBatchModify,
	name: 'Modify batch',
	path: '/fleet/batch/modify/:code',
	permissions: [PermissionsType.Fine],
	icon: 'pencil',
};
