import type { FC } from 'react';

import classNames from 'classnames';

import { FielSetLegendStyles, FieldsetAllBordersStyles, FieldsetStyles } from './styles';

type FieldsetProps = {
	children: JSX.Element;
	legend: string;
	className?: string;
	/**
	 * Se usa para que ponga todos los bordes, no solo el de arriba
	 */
	allBorders?: boolean;
	renderTopRight?: JSX.Element;
};

export const Fieldset: FC<FieldsetProps> = ({ children, legend, className, allBorders, renderTopRight }) => {
	if (allBorders) {
		return (
			<FieldsetAllBordersStyles className={className}>
				<FielSetLegendStyles className={classNames('text-muted d-flex')}>
					<div>{legend}</div>
					{renderTopRight ? <div className="position-absolute end-0 me-3">{renderTopRight}</div> : null}
				</FielSetLegendStyles>
				<div>{children}</div>
			</FieldsetAllBordersStyles>
		);
	}
	return (
		<FieldsetStyles className={className}>
			<FielSetLegendStyles className={classNames('text-muted')}>
				<div>{legend}</div>
				{renderTopRight ? <div className="position-absolute end-0 me-3">{renderTopRight}</div> : null}
			</FielSetLegendStyles>
			<div>{children}</div>
		</FieldsetStyles>
	);
};
