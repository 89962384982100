import React from 'react';

import CreatableSelect from 'react-select/creatable';

import { getOptionsFromValue } from './helpers';
import { useSelectFieldProps } from './hooks';
import { SelectController } from './SelectController';
import type { ISelectCreatableProps, ISelectOption } from './types';

export const CreatableSelectField = <T,>({ onChange, ...rest }: ISelectCreatableProps<T>) => {
	const {
		// Custom component or default 'CreatableSelect' element
		component: Component = CreatableSelect,
		...props
	} = useSelectFieldProps(rest);

	return (
		<SelectController {...rest} onChange={onChange} registerOptions={rest.validation}>
			{(field) => (
				<Component
					{...props}
					{...field}
					getOptionLabel={(item: ISelectOption<T>) => item.label}
					getOptionValue={(item: ISelectOption<T>) => item.value}
					value={getOptionsFromValue(field.value, rest.options)}
				/>
			)}
		</SelectController>
	);
};
