import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const WebCache = lazyWithRetry(() => import('./pages/WebCache'));

export const webcacheRoutes: { [key: number]: IRouteConfig } = {};

webcacheRoutes[routesIdMap.ITWebCache] = {
	component: WebCache,
	exact: true,
	id: routesIdMap.ITWebCache,
	name: 'Web cache',
	path: '/it/web-cache',
	permissions: [PermissionsType.IT],
	icon: 'cloud',
	menuText: 'Web cache',
};
