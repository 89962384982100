import { formatDateByType, getOnlyDateFromJsDate } from './helpers';
import type {
	IInputControllerProps,
	IInputDateProps,
	IInputFieldProps,
	IInputNumberProps,
	IInputTextProps,
} from './types';
import { getValidationValue, setDefaultValidationMessage, setValidationValue } from '../helpers';

export const useInputFieldTextProps = (rest: IInputFieldProps<string> | IInputTextProps) => {
	const { onlyDigits, ...props } = rest as IInputTextProps;
	props.type ||= 'text';
	if (onlyDigits) {
		props.inputMode = 'numeric';
	}

	if (!rest.validation) {
		return props;
	}

	const validation = { ...rest.validation };

	setDefaultValidationMessage(validation, 'minLength', 'The minimum length is {0} characters');
	setDefaultValidationMessage(validation, 'maxLength', 'The maximum length is {0} characters');

	return {
		...props,
		validation,
		/*
		 * Uncomment the following lines if minLength and maxLength validation are required.
		 * minLength: getValidationValue<number>(validation.minLength) ?? props.minLength,
		 * maxLength: getValidationValue<number>(validation.maxLength) ?? props.maxLength,
		 */
	};
};

export const useInputFieldNumberProps = ({ onlyDigits, ...rest }: IInputNumberProps<number | null>) => {
	const props = {
		...rest,
		type: 'number',
	};

	if (onlyDigits) {
		props.inputMode = 'numeric';
	}

	if (!rest.validation) {
		return props;
	}

	const validation = { ...rest.validation };

	setDefaultValidationMessage(validation, 'min', 'The minimum value allowed is {0}');
	setDefaultValidationMessage(validation, 'max', 'The maximum value allowed is {0}');

	return {
		...props,
		validation,
		min: getValidationValue<number>(validation.min as number) ?? props.min,
		max: getValidationValue<number>(validation.max as number) ?? props.max,
		step: getValidationValue<number>(validation.step) ?? props.step,
	};
};

export const useInputFieldDateProps = (rest: IInputDateProps<Date | null>) => {
	const type = rest.type || 'date';

	const props = {
		...rest,
		type,
	};

	if (!rest.validation) {
		return props;
	}

	const validation = { ...rest.validation };

	// Format date to be displayed in error messages
	const formatInfo = (value: string | Date | null) =>
		formatDateByType(value, type, {
			date: 'dd/MM/yyyy',
			'datetime-local': 'dd/MM/yyyy HH:mm',
		}) ?? '';

	// Get min and max values from validation
	const min = getValidationValue<Date>(validation.min as Date);
	let minValue = null;
	if (min) {
		if (type === 'date') {
			// Remove hours, minutes and seconds from date
			setValidationValue(validation, 'min', getOnlyDateFromJsDate(min));
		}
		minValue = formatDateByType(min, type);
		setDefaultValidationMessage(validation, 'min', 'The minimum date allowed is {0}', formatInfo);
	}

	const max = getValidationValue<Date>(validation.max as Date);
	let maxValue = null;
	if (max) {
		if (type === 'date') {
			// Remove hours, minutes and seconds from date
			setValidationValue(validation, 'max', getOnlyDateFromJsDate(max));
		}
		maxValue = formatDateByType(max, type);
		setDefaultValidationMessage(validation, 'max', 'The maximum date allowed is {0}', formatInfo);
	}

	return {
		...props,
		validation,
		min: minValue ?? props.min,
		max: maxValue ?? props.max,
	};
};

export const useInputFieldCheckboxProps = (rest: IInputControllerProps<boolean>) => {
	const props = {
		...rest,
		type: 'checkbox',
	};

	if (!rest.validation) {
		return props;
	}

	const validation = { ...rest.validation };

	return {
		...props,
		validation,
	};
};

export const useInputFieldRadioProps = (rest: IInputControllerProps<boolean>) => {
	const props = {
		...rest,
		type: 'radio',
	};

	if (!rest.validation) {
		return props;
	}

	const validation = { ...rest.validation };

	return {
		...props,
		validation,
	};
};

export const useInputFieldFileProps = (rest: IInputControllerProps<File[]>) => {
	const props = {
		...rest,
		type: 'file',
	};

	if (!rest.validation) {
		return props;
	}

	const validation = { ...rest.validation };

	return {
		...props,
		validation,
	};
};

export const useInputFieldTimeProps = (rest: IInputControllerProps<string>) => {
	const props = {
		...rest,
		type: 'time',
	};

	if (!rest.validation) {
		return props;
	}

	const validation = { ...rest.validation };

	return {
		...props,
		validation,
	};
};
