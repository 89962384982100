import { SaleTicketEndpoints } from './SaleTicketEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleTicket } from '../entities/SaleTicket';

export interface ISaleTicketGetByPlateNumberParams extends IServiceBaseParams {
	plateNumber: string;
}

export const saleTicketGetByPlateNumberService = (params: ISaleTicketGetByPlateNumberParams) => {
	return Api.get<ISaleTicket[], ISaleTicketGetByPlateNumberParams>(SaleTicketEndpoints.GET_BY_PLATE_NUMBER, params);
};
